import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';

const List = () => {

    const [filter, setFilter] = useState('');
    const [filteredLinks, setFilteredLinks] = useState([]);
    const [noResults, setNoResults] = useState(true);
  
    const links = [
      { id: 1, text: 'Location 1' },
      { id: 2, text: 'Location 2' },
      { id: 3, text: 'Location 3' },
    ];
  
    useEffect(() => {
      const newFilteredLinks = links.filter(link => link.text.toLowerCase().includes(filter));
      setFilteredLinks(newFilteredLinks);
      setNoResults(newFilteredLinks.length === 0);
    }, [filter]);
  
    const handleSearchInputChange = (event) => {
      setFilter(event.target.value.toLowerCase());
    };

    const [selectedValue, setSelectedValue] = useState('option1');

    const handleChange = (event) => {
      setSelectedValue(event.target.value);
    };

    return ( 
    <div className="listClass">
         
      {/* <div id="locations">
        {filteredLinks.map(link => (
          <a key={link.id} href="#">{link.text}</a>
        ))}
      </div>
      {noResults && <div id="noResults" style={{ display: 'block' }}>No results found</div>} */}
    
        <div className="heroSec1 listPage">
	<div className="inner">
		
		<div className="container">
			<div className="row ai jc">
				
				<div className="col-lg-12" align="center">
					<form method="POST" action="0000000000000000000000">
						
						<div className="HomeSearchBig">
							
							<div className="flex  jcb ai">
								
								<div className="flex heroSearOpt1">
									<label className="searFiltTypeOpt active">
										<input type="hidden" name="search-type" value="buy"/>
										BUY
									</label>
									<label className="searFiltTypeOpt">
										<input type="hidden" name="search-type" value="rent"/>
										RENT
									</label>
									<label className="searFiltTypeOpt">
										<input type="hidden" name="search-type" value="sell"/>
										SELL
									</label>
								</div> 

								<span className="advSearBtn11">Advance</span>


							</div>


							<div className="heroSearSec2">
								<div className="flex mainHeroSearch">
									
										{/* <input type="text" name="location" placeholder="Location" className="searHeroInp1 locationinp"/> */}

                                        <input
                                            id="searchInput"
                                            type="text"
                                            placeholder="Location"
                                            className="searHeroInp1 locationinp"
                                            onChange={handleSearchInputChange}
                                        />

										<select  value={selectedValue} className="searHeroInp1 cityList" name="city">
											    <option value="" disabled selected>City</option>
											    <option value="Islamabad">Islamabad</option>
											    <option value="" disabled>Punjab Cities</option>
											    <option value="Ahmed Nager Chatha">Ahmed Nager Chatha</option>
											    <option value="Ahmadpur East">Ahmadpur East</option>
											    <option value="Ali Khan Abad">Ali Khan Abad</option>
											    <option value="Alipur">Alipur</option>
											    <option value="Arifwala">Arifwala</option>
											    <option value="Attock">Attock</option>
											    <option value="Bhera">Bhera</option>
											    <option value="Bhalwal">Bhalwal</option>
											    <option value="Bahawalnagar">Bahawalnagar</option>
											    <option value="Bahawalpur">Bahawalpur</option>
											    <option value="Bhakkar">Bhakkar</option>
											    <option value="Burewala">Burewala</option>
											    <option value="Chillianwala">Chillianwala</option>
											    <option value="Chakwal">Chakwal</option>
											    <option value="Chichawatni">Chichawatni</option>
											    <option value="Chiniot">Chiniot</option>
											    <option value="Chishtian">Chishtian</option>
											    <option value="Daska">Daska</option>
											    <option value="Darya Khan">Darya Khan</option>
											    <option value="Dera Ghazi Khan">Dera Ghazi Khan</option>
											    <option value="Dhaular">Dhaular</option>
											    <option value="Dina">Dina</option>
											    <option value="Dinga">Dinga</option>
											    <option value="Dipalpur">Dipalpur</option>
											    <option value="Faisalabad">Faisalabad</option>
											    <option value="Ferozewala">Ferozewala</option>
											    <option value="Fateh Jhang">Fateh Jang</option>
											    <option value="Ghakhar Mandi">Ghakhar Mandi</option>
											    <option value="Gojra">Gojra</option>
											    <option value="Gujranwala">Gujranwala</option>
											    <option value="Gujrat">Gujrat</option>
											    <option value="Gujar Khan">Gujar Khan</option>
											    <option value="Hafizabad">Hafizabad</option>
											    <option value="Haroonabad">Haroonabad</option>
											    <option value="Hasilpur">Hasilpur</option>
											    <option value="Haveli Lakha">Haveli Lakha</option>
											    <option value="Jatoi">Jatoi</option>
											    <option value="Jalalpur">Jalalpur</option>
											    <option value="Jattan">Jattan</option>
											    <option value="Jampur">Jampur</option>
											    <option value="Jaranwala">Jaranwala</option>
											    <option value="Jhang">Jhang</option>
											    <option value="Jhelum">Jhelum</option>
											    <option value="Kalabagh">Kalabagh</option>
											    <option value="Karor Lal Esan">Karor Lal Esan</option>
											    <option value="Kasur">Kasur</option>
											    <option value="Kamalia">Kamalia</option>
											    <option value="Kamoke">Kamoke</option>
											    <option value="Khanewal">Khanewal</option>
											    <option value="Khanpur">Khanpur</option>
											    <option value="Kharian">Kharian</option>
											    <option value="Khushab">Khushab</option>
											    <option value="Kot Addu">Kot Addu</option>
											    <option value="Jauharabad">Jauharabad</option>
											    <option value="Lahore">Lahore</option>
											    <option value="Lalamusa">Lalamusa</option>
											    <option value="Layyah">Layyah</option>
											    <option value="Liaquat Pur">Liaquat Pur</option>
											    <option value="Lodhran">Lodhran</option>
											    <option value="Malakwal">Malakwal</option>
											    <option value="Mamoori">Mamoori</option>
											    <option value="Mailsi">Mailsi</option>
											    <option value="Mandi Bahauddin">Mandi Bahauddin</option>
											    <option value="Mian Channu">Mian Channu</option>
											    <option value="Mianwali">Mianwali</option>
											    <option value="Multan">Multan</option>
											    <option value="Murree">Murree</option>
											    <option value="Muridke">Muridke</option>
											    <option value="Mianwali Bangla">Mianwali Bangla</option>
											    <option value="Muzaffargarh">Muzaffargarh</option>
											    <option value="Narowal">Narowal</option>
											    <option value="Nankana Sahib">Nankana Sahib</option>
											    <option value="Okara">Okara</option>
											    <option value="Renala Khurd">Renala Khurd</option>
											    <option value="Pakpattan">Pakpattan</option>
											    <option value="Pattoki">Pattoki</option>
											    <option value="Pir Mahal">Pir Mahal</option>
											    <option value="Qaimpur">Qaimpur</option>
											    <option value="Qila Didar Singh">Qila Didar Singh</option>
											    <option value="Rabwah">Rabwah</option>
											    <option value="Raiwind">Raiwind</option>
											    <option value="Rajanpur">Rajanpur</option>
											    <option value="Rahim Yar Khan">Rahim Yar Khan</option>
											    <option value="Rawalpindi">Rawalpindi</option>
											    <option value="Sadiqabad">Sadiqabad</option>
											    <option value="Safdarabad">Safdarabad</option>
											    <option value="Sahiwal">Sahiwal</option>
											    <option value="Sangla Hill">Sangla Hill</option>
											    <option value="Sarai Alamgir">Sarai Alamgir</option>
											    <option value="Sargodha">Sargodha</option>
											    <option value="Shakargarh">Shakargarh</option>
											    <option value="Sheikhupura">Sheikhupura</option>
											    <option value="Sialkot">Sialkot</option>
											    <option value="Sohawa">Sohawa</option>
											    <option value="Soianwala">Soianwala</option>
											    <option value="Siranwali">Siranwali</option>
											    <option value="Talagang">Talagang</option>
											    <option value="Taxila">Taxila</option>
											    <option value="Toba Tek Singh">Toba Tek Singh</option>
											    <option value="Vehari">Vehari</option>
											    <option value="Wah Cantonment">Wah Cantonment</option>
											    <option value="Wazirabad">Wazirabad</option>
											    <option value="" disabled>Sindh Cities</option>
											    <option value="Badin">Badin</option>
											    <option value="Bhirkan">Bhirkan</option>
											    <option value="Rajo Khanani">Rajo Khanani</option>
											    <option value="Chak">Chak</option>
											    <option value="Dadu">Dadu</option>
											    <option value="Digri">Digri</option>
											    <option value="Diplo">Diplo</option>
											    <option value="Dokri">Dokri</option>
											    <option value="Ghotki">Ghotki</option>
											    <option value="Haala">Haala</option>
											    <option value="Hyderabad">Hyderabad</option>
											    <option value="Islamkot">Islamkot</option>
											    <option value="Jacobabad">Jacobabad</option>
											    <option value="Jamshoro">Jamshoro</option>
											    <option value="Jungshahi">Jungshahi</option>
											    <option value="Kandhkot">Kandhkot</option>
											    <option value="Kandiaro">Kandiaro</option>
											    <option value="Karachi">Karachi</option>
											    <option value="Kashmore">Kashmore</option>
											    <option value="Keti Bandar">Keti Bandar</option>
											    <option value="Khairpur">Khairpur</option>
											    <option value="Kotri">Kotri</option>
											    <option value="Larkana">Larkana</option>
											    <option value="Matiari">Matiari</option>
											    <option value="Mehar">Mehar</option>
											    <option value="Mirpur Khas">Mirpur Khas</option>
											    <option value="Mithani">Mithani</option>
											    <option value="Mithi">Mithi</option>
											    <option value="Mehrabpur">Mehrabpur</option>
											    <option value="Moro">Moro</option>
											    <option value="Nagarparkar">Nagarparkar</option>
											    <option value="Naudero">Naudero</option>
											    <option value="Naushahro Feroze">Naushahro Feroze</option>
											    <option value="Naushara">Naushara</option>
											    <option value="Nawabshah">Nawabshah</option>
											    <option value="Nazimabad">Nazimabad</option>
											    <option value="Qambar">Qambar</option>
											    <option value="Qasimabad">Qasimabad</option>
											    <option value="Ranipur">Ranipur</option>
											    <option value="Ratodero">Ratodero</option>
											    <option value="Rohri">Rohri</option>
											    <option value="Sakrand">Sakrand</option>
											    <option value="Sanghar">Sanghar</option>
											    <option value="Shahbandar">Shahbandar</option>
											    <option value="Shahdadkot">Shahdadkot</option>
											    <option value="Shahdadpur">Shahdadpur</option>
											    <option value="Shahpur Chakar">Shahpur Chakar</option>
											    <option value="Shikarpaur">Shikarpaur</option>
											    <option value="Sukkur">Sukkur</option>
											    <option value="Tangwani">Tangwani</option>
											    <option value="Tando Adam Khan">Tando Adam Khan</option>
											    <option value="Tando Allahyar">Tando Allahyar</option>
											    <option value="Tando Muhammad Khan">Tando Muhammad Khan</option>
											    <option value="Thatta">Thatta</option>
											    <option value="Umerkot">Umerkot</option>
											    <option value="Warah">Warah</option>
											    <option value="" disabled>Khyber Cities</option>
											    <option value="Abbottabad">Abbottabad</option>
											    <option value="Adezai">Adezai</option>
											    <option value="Alpuri">Alpuri</option>
											    <option value="Akora Khattak">Akora Khattak</option>
											    <option value="Ayubia">Ayubia</option>
											    <option value="Banda Daud Shah">Banda Daud Shah</option>
											    <option value="Bannu">Bannu</option>
											    <option value="Batkhela">Batkhela</option>
											    <option value="Battagram">Battagram</option>
											    <option value="Birote">Birote</option>
											    <option value="Chakdara">Chakdara</option>
											    <option value="Charsadda">Charsadda</option>
											    <option value="Chitral">Chitral</option>
											    <option value="Daggar">Daggar</option>
											    <option value="Dargai">Dargai</option>
											    <option value="Darya Khan">Darya Khan</option>
											    <option value="Dera Ismail Khan">Dera Ismail Khan</option>
											    <option value="Doaba">Doaba</option>
											    <option value="Dir">Dir</option>
											    <option value="Drosh">Drosh</option>
											    <option value="Hangu">Hangu</option>
											    <option value="Haripur">Haripur</option>
											    <option value="Karak">Karak</option>
											    <option value="Kohat">Kohat</option>
											    <option value="Kulachi">Kulachi</option>
											    <option value="Lakki Marwat">Lakki Marwat</option>
											    <option value="Latamber">Latamber</option>
											    <option value="Madyan">Madyan</option>
											    <option value="Mansehra">Mansehra</option>
											    <option value="Mardan">Mardan</option>
											    <option value="Mastuj">Mastuj</option>
											    <option value="Mingora">Mingora</option>
											    <option value="Nowshera">Nowshera</option>
											    <option value="Paharpur">Paharpur</option>
											    <option value="Pabbi">Pabbi</option>
											    <option value="Peshawar">Peshawar</option>
											    <option value="Saidu Sharif">Saidu Sharif</option>
											    <option value="Shorkot">Shorkot</option>
											    <option value="Shewa Adda">Shewa Adda</option>
											    <option value="Swabi">Swabi</option>
											    <option value="Swat">Swat</option>
											    <option value="Tangi">Tangi</option>
											    <option value="Tank">Tank</option>
											    <option value="Thall">Thall</option>
											    <option value="Timergara">Timergara</option>
											    <option value="Tordher">Tordher</option>
											    <option value="" disabled>Balochistan Cities</option>
											    <option value="Awaran">Awaran</option>
											    <option value="Barkhan">Barkhan</option>
											    <option value="Chagai">Chagai</option>
											    <option value="Dera Bugti">Dera Bugti</option>
											    <option value="Gwadar">Gwadar</option>
											    <option value="Harnai">Harnai</option>
											    <option value="Jafarabad">Jafarabad</option>
											    <option value="Jhal Magsi">Jhal Magsi</option>
											    <option value="Kacchi">Kacchi</option>
											    <option value="Kalat">Kalat</option>
											    <option value="Kech">Kech</option>
											    <option value="Kharan">Kharan</option>
											    <option value="Khuzdar">Khuzdar</option>
											    <option value="Killa Abdullah">Killa Abdullah</option>
											    <option value="Killa Saifullah">Killa Saifullah</option>
											    <option value="Kohlu">Kohlu</option>
											    <option value="Lasbela">Lasbela</option>
											    <option value="Lehri">Lehri</option>
											    <option value="Loralai">Loralai</option>
											    <option value="Mastung">Mastung</option>
											    <option value="Musakhel">Musakhel</option>
											    <option value="Nasirabad">Nasirabad</option>
											    <option value="Nushki">Nushki</option>
											    <option value="Panjgur">Panjgur</option>
											    <option value="Pishin Valley">Pishin Valley</option>
											    <option value="Quetta">Quetta</option>
											    <option value="Sherani">Sherani</option>
											    <option value="Sibi">Sibi</option>
											    <option value="Sohbatpur">Sohbatpur</option>
											    <option value="Washuk">Washuk</option>
											    <option value="Zhob">Zhob</option>
											    <option value="Ziarat">Ziarat</option>
											  </select>


										<button type="submit" className="btn1 herosearhBtn">
											<i className="fa fa-search fs11"></i>
											&nbsp;
											<span className="findTxt">Find</span>
										</button>

								</div>


							</div> 


							<div className="advSearBack">
								<div className="row">

									<div className="col-lg-3">
										<label className="lbl">Property Type</label>
										<select className="inpadvHome" name="prop_type">
											<option value="House">House</option>
											<option value="Flat">Flat</option>
											<option value="Apartment">Apartment</option>
											<option value="Office">Office</option>
											<option value="Shop">Shop</option>
										</select>
									</div>

									<div className="col-lg-3">
										<label className="lbl">Price (PKR)</label>
										<div className="flex pricHomAdvFlex">
											<input type="number" name="price_min" className="inpadvHome" placeholder="Min."/>
											&nbsp;&nbsp;&nbsp;
											<input type="number" name="price_max" className="inpadvHome" placeholder="Max."/>
										</div>
									</div>

									<div className="col-lg-3">
										<label className="lbl">AREA (Sq. Yd.)</label>
										<div className="flex pricHomAdvFlex">
											<input type="number" name="price_min" className="inpadvHome" placeholder="Min."/>
											&nbsp;&nbsp;&nbsp;
											<input type="number" name="price_max" className="inpadvHome" placeholder="Max."/>
										</div>
									</div>

									<div className="col-lg-3">
										<label className="lbl">beds</label>
										<select className="inpadvHome" name="prop_type">
											<option value="1">1</option>
											<option value="2">2</option>
											<option value="3">3</option>
											<option value="4">4</option>
											<option value="5">5</option>
										</select>
									</div>

								</div>
							</div>


						</div>



						<div className="searBellowPiulls">
							<div className="flex fwrap jc">

								<a href="0000000000000000000000">Karachi</a>
								<a href="0000000000000000000000">Lahore</a>
								<a href="0000000000000000000000">Islamabad</a>
								<a href="0000000000000000000000">Faisalabad</a>
								<a href="0000000000000000000000">Multan</a>
								<a href="0000000000000000000000">Quetta</a>
								<a href="0000000000000000000000">Larkana</a>
								
							</div>
						</div> 



					</form>
				</div> 

			</div>
		</div>

	</div>

</div>




<div className="listPage padder1">
	<div className="container">
		
		<div className="row">
			
			<div className="col-lg-12">

				<div className="row">


					<div className="col-lg-3">
						<h2 className="widget-heading">Properties in Karachi</h2>
						<p className="fs13 cgray">
							Found total of <b>20,457</b> in Karachi
						</p>
					</div>

					<div className="col-lg-5">
						<p className="mb5">Property types</p>
						<div className="flex linkers ai">
							
							<a href="0000000000000000000000" className="csecond underline">Houses (12,454)</a>
							<span>|</span>
							<a href="0000000000000000000000" className="csecond underline">Shops (8,457)</a>
							<span>|</span>
							<a href="0000000000000000000000" className="csecond underline">Offices (5,445)</a>

						</div>
					</div>

				</div>


			</div> 


			<div className="col-lg-9">
				
				<div className="propertyListBack">
					
					<div className="row">
						


						<div className="col-lg-4">
							<div className="propertyGridBoxx featPropList">

								<div className="thumbBack">
									<Slider
										dots={true}
										arrows={true}
										className="slider-list-img"
									>
										<div className="item">
											<img src="img/prop2.png" alt="Property 2" />
										</div>
										<div className="item">
											<img src="img/prop1.png" alt="Property 1" />
										</div>
										<div className="item">
											<img src="img/prop3.png" alt="Property 3" />
										</div>
									</Slider>
									<span className="tag feat" title="Property featured by myProperty.pk">
										Featured
									</span>
									<div className="numOfScreens" title="Pictures available of property">
										<i className="fa fa-camera fs13"></i>&nbsp;
										<span>4</span>
									</div>
									<div class="favourite">
										<a 
											href="0000:"
											onMouseEnter={(e) => e.currentTarget.querySelector('i').classList.replace('fa-heart-o', 'fa-heart')}
											onMouseLeave={(e) => e.currentTarget.querySelector('i').classList.replace('fa-heart', 'fa-heart-o')}
										>
											<i className="fa fa-heart-o"></i>
										</a>
									</div>
									<div className="propSocThuBack flex">
										<a href="0000000000000000000000" title="Share on Facebook">
											<i className="fa fa-facebook"></i>
										</a>
										<a href="0000000000000000000000" title="Share on Twitter">
											<i className="fa fa-twitter"></i>
										</a>
										<a href="0000000000000000000000" title="Share on Instagram">
											<i className="fa fa-instagram"></i>
										</a>
										<a href="0000000000000000000000" title="Share on Facebook">
											<i className="fa fa-linkedin"></i>
										</a>
										<a href="0000000000000000000000" title="Share on Email">
											<i className="fa fa-envelope"></i>
										</a>
									</div>
								</div>

								<div className="inner1">
								
									<a href="0000000000000000000000" className="title" title="5 Rooms luxury appartment in DHA Phase 8, Karachi">
										5 Rooms luxury appartment in DHA Phase 8, Karachi
									</a>

									<p className="loc1">
										<i className="fa fa-map-marker fs13"></i>
										<span>Karachi, Pakistan</span>
									</p>

								</div>

								<div className="inner2 inner1">

									<div className="flex proplistFeats ai jcb">
										<div className="cont" title="Beds">
											<img src="img/propimg1.png"/>
											<span>
												4
											</span>
										</div>
										<div className="cont" title="Bathrooms">
											<img src="img/propimg2.png"/>
											<span>
												2
											</span>
										</div>
										<div className="cont" title="Garage">
											<img src="img/propimg3.png"/>
											<span>
												1
											</span>
										</div>
										<div className="cont" title="Bathrooms">
											<img src="img/propimg4.png"/>
											<span>
												240 Sq/feets
											</span>
										</div>
									</div>

								</div>


								<div className="inner2 inner1">
									<div className="flex propListFlex ai">

											<a href="0000000000000000000000" title="JAIDAD Assosiates">
												<img src="img/agent8.png" className="agentimg"/>
											</a>

											&nbsp;&nbsp;&nbsp;

											<a href="0000000000000000000000" className="btns btn2">
												View Details
											</a>

											&nbsp;&nbsp;&nbsp;

											<a href="0000000000000000000000" className="btns btn1" title="Call agent">
												<i className="fa fa-phone"></i>
											</a>

									</div>
								</div>


							</div>
						</div> 


						<div className="col-lg-4">
							<div className="propertyGridBoxx featPropList">

								<div className="thumbBack">
									<Slider
										dots={true}
										arrows={true}
										className="slider-list-img"
									>
										<div className="item">
											<img src="img/prop2.png" alt="Property 2" />
										</div>
										<div className="item">
											<img src="img/prop1.png" alt="Property 1" />
										</div>
										<div className="item">
											<img src="img/prop3.png" alt="Property 3" />
										</div>
									</Slider>
									<span className="tag feat" title="Property featured by myProperty.pk">
										Featured
									</span>
									<div className="numOfScreens" title="Pictures available of property">
										<i className="fa fa-camera fs13"></i>&nbsp;
										<span>4</span>
									</div>
									<div class="favourite">
										<a 
											href="0000:"
											onMouseEnter={(e) => e.currentTarget.querySelector('i').classList.replace('fa-heart-o', 'fa-heart')}
											onMouseLeave={(e) => e.currentTarget.querySelector('i').classList.replace('fa-heart', 'fa-heart-o')}
										>
											<i className="fa fa-heart-o"></i>
										</a>
									</div>
									<div className="propSocThuBack flex">
										<a href="0000000000000000000000" title="Share on Facebook">
											<i className="fa fa-facebook"></i>
										</a>
										<a href="0000000000000000000000" title="Share on Twitter">
											<i className="fa fa-twitter"></i>
										</a>
										<a href="0000000000000000000000" title="Share on Instagram">
											<i className="fa fa-instagram"></i>
										</a>
										<a href="0000000000000000000000" title="Share on Facebook">
											<i className="fa fa-linkedin"></i>
										</a>
										<a href="0000000000000000000000" title="Share on Email">
											<i className="fa fa-envelope"></i>
										</a>
									</div>
								</div>

								<div className="inner1">
								
									<a href="0000000000000000000000" className="title" title="5 Rooms luxury appartment in DHA Phase 8, Karachi">
										5 Rooms luxury appartment in DHA Phase 8, Karachi
									</a>

									<p className="loc1">
										<i className="fa fa-map-marker fs13"></i>
										<span>Karachi, Pakistan</span>
									</p>

								</div>

								<div className="inner2 inner1">

									<div className="flex proplistFeats ai jcb">
										<div className="cont" title="Beds">
											<img src="img/propimg1.png"/>
											<span>
												4
											</span>
										</div>
										<div className="cont" title="Bathrooms">
											<img src="img/propimg2.png"/>
											<span>
												2
											</span>
										</div>
										<div className="cont" title="Garage">
											<img src="img/propimg3.png"/>
											<span>
												1
											</span>
										</div>
										<div className="cont" title="Bathrooms">
											<img src="img/propimg4.png"/>
											<span>
												240 Sq/feets
											</span>
										</div>
									</div>

								</div>


								<div className="inner2 inner1">
									<div className="flex propListFlex ai">

											<a href="0000000000000000000000" title="JAIDAD Assosiates">
												<img src="img/agent8.png" className="agentimg"/>
											</a>

											&nbsp;&nbsp;&nbsp;

											<a href="0000000000000000000000" className="btns btn2">
												View Details
											</a>

											&nbsp;&nbsp;&nbsp;

											<a href="0000000000000000000000" className="btns btn1" title="Call agent">
												<i className="fa fa-phone"></i>
											</a>

									</div>
								</div>


							</div>
						</div> 


						<div className="col-lg-4">
							<div className="propertyGridBoxx featPropList">

								<div className="thumbBack">
									<Slider
										dots={true}
										arrows={true}
										className="slider-list-img"
									>
										<div className="item">
											<img src="img/prop2.png" alt="Property 2" />
										</div>
										<div className="item">
											<img src="img/prop1.png" alt="Property 1" />
										</div>
										<div className="item">
											<img src="img/prop3.png" alt="Property 3" />
										</div>
									</Slider>
									<span className="tag feat" title="Property featured by myProperty.pk">
										Featured
									</span>
									<div className="numOfScreens" title="Pictures available of property">
										<i className="fa fa-camera fs13"></i>&nbsp;
										<span>4</span>
									</div>
									<div class="favourite">
										<a 
											href="0000:"
											onMouseEnter={(e) => e.currentTarget.querySelector('i').classList.replace('fa-heart-o', 'fa-heart')}
											onMouseLeave={(e) => e.currentTarget.querySelector('i').classList.replace('fa-heart', 'fa-heart-o')}
										>
											<i className="fa fa-heart-o"></i>
										</a>
									</div>
									<div className="propSocThuBack flex">
										<a href="0000000000000000000000" title="Share on Facebook">
											<i className="fa fa-facebook"></i>
										</a>
										<a href="0000000000000000000000" title="Share on Twitter">
											<i className="fa fa-twitter"></i>
										</a>
										<a href="0000000000000000000000" title="Share on Instagram">
											<i className="fa fa-instagram"></i>
										</a>
										<a href="0000000000000000000000" title="Share on Facebook">
											<i className="fa fa-linkedin"></i>
										</a>
										<a href="0000000000000000000000" title="Share on Email">
											<i className="fa fa-envelope"></i>
										</a>
									</div>
								</div>

								<div className="inner1">
								
									<a href="0000000000000000000000" className="title" title="5 Rooms luxury appartment in DHA Phase 8, Karachi">
										5 Rooms luxury appartment in DHA Phase 8, Karachi
									</a>

									<p className="loc1">
										<i className="fa fa-map-marker fs13"></i>
										<span>Karachi, Pakistan</span>
									</p>

								</div> 

								<div className="inner2 inner1">

									<div className="flex proplistFeats ai jcb">
										<div className="cont" title="Beds">
											<img src="img/propimg1.png"/>
											<span>
												4
											</span>
										</div>
										<div className="cont" title="Bathrooms">
											<img src="img/propimg2.png"/>
											<span>
												2
											</span>
										</div>
										<div className="cont" title="Garage">
											<img src="img/propimg3.png"/>
											<span>
												1
											</span>
										</div>
										<div className="cont" title="Bathrooms">
											<img src="img/propimg4.png"/>
											<span>
												240 Sq/feets
											</span>
										</div>
									</div>

								</div>


								<div className="inner2 inner1">
									<div className="flex propListFlex ai">

											<a href="0000000000000000000000" title="JAIDAD Assosiates">
												<img src="img/agent8.png" className="agentimg"/>
											</a>

											&nbsp;&nbsp;&nbsp;

											<a href="0000000000000000000000" className="btns btn2">
												View Details
											</a>

											&nbsp;&nbsp;&nbsp;

											<a href="0000000000000000000000" className="btns btn1" title="Call agent">
												<i className="fa fa-phone"></i>
											</a>

									</div>
								</div>


							</div>
						</div> 


						<div className="col-lg-4">
							<div className="propertyGridBoxx featPropList">

								<div className="thumbBack">
									<Slider
										dots={true}
										arrows={true}
										className="slider-list-img"
									>
										<div className="item">
											<img src="img/prop2.png" alt="Property 2" />
										</div>
										<div className="item">
											<img src="img/prop1.png" alt="Property 1" />
										</div>
										<div className="item">
											<img src="img/prop3.png" alt="Property 3" />
										</div>
									</Slider>
									<span className="tag feat" title="Property featured by myProperty.pk">
										Featured
									</span>
									<div className="numOfScreens" title="Pictures available of property">
										<i className="fa fa-camera fs13"></i>&nbsp;
										<span>4</span>
									</div>
									<div class="favourite">
										<a 
											href="0000:"
											onMouseEnter={(e) => e.currentTarget.querySelector('i').classList.replace('fa-heart-o', 'fa-heart')}
											onMouseLeave={(e) => e.currentTarget.querySelector('i').classList.replace('fa-heart', 'fa-heart-o')}
										>
											<i className="fa fa-heart-o"></i>
										</a>
									</div>
									<div className="propSocThuBack flex">
										<a href="0000000000000000000000" title="Share on Facebook">
											<i className="fa fa-facebook"></i>
										</a>
										<a href="0000000000000000000000" title="Share on Twitter">
											<i className="fa fa-twitter"></i>
										</a>
										<a href="0000000000000000000000" title="Share on Instagram">
											<i className="fa fa-instagram"></i>
										</a>
										<a href="0000000000000000000000" title="Share on Facebook">
											<i className="fa fa-linkedin"></i>
										</a>
										<a href="0000000000000000000000" title="Share on Email">
											<i className="fa fa-envelope"></i>
										</a>
									</div>
								</div>

								<div className="inner1">
								
									<a href="0000000000000000000000" className="title" title="5 Rooms luxury appartment in DHA Phase 8, Karachi">
										5 Rooms luxury appartment in DHA Phase 8, Karachi
									</a>

									<p className="loc1">
										<i className="fa fa-map-marker fs13"></i>
										<span>Karachi, Pakistan</span>
									</p>

								</div> 

								<div className="inner2 inner1">

									<div className="flex proplistFeats ai jcb">
										<div className="cont" title="Beds">
											<img src="img/propimg1.png"/>
											<span>
												4
											</span>
										</div>
										<div className="cont" title="Bathrooms">
											<img src="img/propimg2.png"/>
											<span>
												2
											</span>
										</div>
										<div className="cont" title="Garage">
											<img src="img/propimg3.png"/>
											<span>
												1
											</span>
										</div>
										<div className="cont" title="Bathrooms">
											<img src="img/propimg4.png"/>
											<span>
												240 Sq/feets
											</span>
										</div>
									</div>

								</div>


								<div className="inner2 inner1">
									<div className="flex propListFlex ai">

											<a href="0000000000000000000000" title="JAIDAD Assosiates">
												<img src="img/agent8.png" className="agentimg"/>
											</a>

											&nbsp;&nbsp;&nbsp;

											<a href="0000000000000000000000" className="btns btn2">
												View Details
											</a>

											&nbsp;&nbsp;&nbsp;

											<a href="0000000000000000000000" className="btns btn1" title="Call agent">
												<i className="fa fa-phone"></i>
											</a>

									</div>
								</div>


							</div>
						</div> 


						<div className="col-lg-4">
							<div className="propertyGridBoxx featPropList">

								<div className="thumbBack">
									<Slider
										dots={true}
										arrows={true}
										className="slider-list-img"
									>
										<div className="item">
											<img src="img/prop2.png" alt="Property 2" />
										</div>
										<div className="item">
											<img src="img/prop1.png" alt="Property 1" />
										</div>
										<div className="item">
											<img src="img/prop3.png" alt="Property 3" />
										</div>
									</Slider>
									<div className="numOfScreens" title="Pictures available of property">
										<i className="fa fa-camera fs13"></i>&nbsp;
										<span>4</span>
									</div>
									<div class="favourite">
										<a 
											href="0000:"
											onMouseEnter={(e) => e.currentTarget.querySelector('i').classList.replace('fa-heart-o', 'fa-heart')}
											onMouseLeave={(e) => e.currentTarget.querySelector('i').classList.replace('fa-heart', 'fa-heart-o')}
										>
											<i className="fa fa-heart-o"></i>
										</a>
									</div>
									<div className="propSocThuBack flex">
										<a href="0000000000000000000000" title="Share on Facebook">
											<i className="fa fa-facebook"></i>
										</a>
										<a href="0000000000000000000000" title="Share on Twitter">
											<i className="fa fa-twitter"></i>
										</a>
										<a href="0000000000000000000000" title="Share on Instagram">
											<i className="fa fa-instagram"></i>
										</a>
										<a href="0000000000000000000000" title="Share on Facebook">
											<i className="fa fa-linkedin"></i>
										</a>
										<a href="0000000000000000000000" title="Share on Email">
											<i className="fa fa-envelope"></i>
										</a>
									</div>
								</div>

								<div className="inner1">
								
									<a href="0000000000000000000000" className="title" title="5 Rooms luxury appartment in DHA Phase 8, Karachi">
										5 Rooms luxury appartment in DHA Phase 8, Karachi
									</a>

									<p className="loc1">
										<i className="fa fa-map-marker fs13"></i>
										<span>Karachi, Pakistan</span>
									</p>

								</div> 

								<div className="inner2 inner1">

									<div className="flex proplistFeats ai jcb">
										<div className="cont" title="Beds">
											<img src="img/propimg1.png"/>
											<span>
												4
											</span>
										</div>
										<div className="cont" title="Bathrooms">
											<img src="img/propimg2.png"/>
											<span>
												2
											</span>
										</div>
										<div className="cont" title="Garage">
											<img src="img/propimg3.png"/>
											<span>
												1
											</span>
										</div>
										<div className="cont" title="Bathrooms">
											<img src="img/propimg4.png"/>
											<span>
												240 Sq/feets
											</span>
										</div>
									</div>

								</div>


								<div className="inner2 inner1">
									<div className="flex propListFlex ai">

											<a href="0000000000000000000000" title="HR Properties">
												<img src="img/agent10.png" className="agentimg"/>
											</a>

											&nbsp;&nbsp;&nbsp;

											<a href="0000000000000000000000" className="btns btn2">
												View Details
											</a>

											&nbsp;&nbsp;&nbsp;

											<a href="0000000000000000000000" className="btns btn1" title="Call agent">
												<i className="fa fa-phone"></i>
											</a>

									</div>
								</div>


							</div>
						</div> 

						<div className="col-lg-4">
							<div className="propertyGridBoxx featPropList">

								<div className="thumbBack">
									<Slider
										dots={true}
										arrows={true}
										className="slider-list-img"
									>
										<div className="item">
											<img src="img/prop2.png" alt="Property 2" />
										</div>
										<div className="item">
											<img src="img/prop1.png" alt="Property 1" />
										</div>
										<div className="item">
											<img src="img/prop3.png" alt="Property 3" />
										</div>
									</Slider>
									<div className="numOfScreens" title="Pictures available of property">
										<i className="fa fa-camera fs13"></i>&nbsp;
										<span>4</span>
									</div>
									<div class="favourite">
										<a 
											href="0000:"
											onMouseEnter={(e) => e.currentTarget.querySelector('i').classList.replace('fa-heart-o', 'fa-heart')}
											onMouseLeave={(e) => e.currentTarget.querySelector('i').classList.replace('fa-heart', 'fa-heart-o')}
										>
											<i className="fa fa-heart-o"></i>
										</a>
									</div>
									<div className="propSocThuBack flex">
										<a href="0000000000000000000000" title="Share on Facebook">
											<i className="fa fa-facebook"></i>
										</a>
										<a href="0000000000000000000000" title="Share on Twitter">
											<i className="fa fa-twitter"></i>
										</a>
										<a href="0000000000000000000000" title="Share on Instagram">
											<i className="fa fa-instagram"></i>
										</a>
										<a href="0000000000000000000000" title="Share on Facebook">
											<i className="fa fa-linkedin"></i>
										</a>
										<a href="0000000000000000000000" title="Share on Email">
											<i className="fa fa-envelope"></i>
										</a>
									</div>
								</div>

								<div className="inner1">
								
									<a href="0000000000000000000000" className="title" title="5 Rooms luxury appartment in DHA Phase 8, Karachi">
										5 Rooms luxury appartment in DHA Phase 8, Karachi
									</a>

									<p className="loc1">
										<i className="fa fa-map-marker fs13"></i>
										<span>Karachi, Pakistan</span>
									</p>

								</div> 

								<div className="inner2 inner1">

									<div className="flex proplistFeats ai jcb">
										<div className="cont" title="Beds">
											<img src="img/propimg1.png"/>
											<span>
												4
											</span>
										</div>
										<div className="cont" title="Bathrooms">
											<img src="img/propimg2.png"/>
											<span>
												2
											</span>
										</div>
										<div className="cont" title="Garage">
											<img src="img/propimg3.png"/>
											<span>
												1
											</span>
										</div>
										<div className="cont" title="Bathrooms">
											<img src="img/propimg4.png"/>
											<span>
												240 Sq/feets
											</span>
										</div>
									</div>

								</div>


								<div className="inner2 inner1">
									<div className="flex propListFlex ai">

											<a href="0000000000000000000000" title="HR Properties">
												<img src="img/agent10.png" className="agentimg"/>
											</a>

											&nbsp;&nbsp;&nbsp;

											<a href="0000000000000000000000" className="btns btn2">
												View Details
											</a>

											&nbsp;&nbsp;&nbsp;

											<a href="0000000000000000000000" className="btns btn1" title="Call agent">
												<i className="fa fa-phone"></i>
											</a>

									</div>
								</div>


							</div>
						</div> 

						<div className="col-lg-4">
							<div className="propertyGridBoxx featPropList">

								<div className="thumbBack">
									<Slider
										dots={true}
										arrows={true}
										className="slider-list-img"
									>
										<div className="item">
											<img src="img/prop2.png" alt="Property 2" />
										</div>
										<div className="item">
											<img src="img/prop1.png" alt="Property 1" />
										</div>
										<div className="item">
											<img src="img/prop3.png" alt="Property 3" />
										</div>
									</Slider>
									<div className="numOfScreens" title="Pictures available of property">
										<i className="fa fa-camera fs13"></i>&nbsp;
										<span>4</span>
									</div>
									<div class="favourite">
										<a 
											href="0000:"
											onMouseEnter={(e) => e.currentTarget.querySelector('i').classList.replace('fa-heart-o', 'fa-heart')}
											onMouseLeave={(e) => e.currentTarget.querySelector('i').classList.replace('fa-heart', 'fa-heart-o')}
										>
											<i className="fa fa-heart-o"></i>
										</a>
									</div>
									<div className="propSocThuBack flex">
										<a href="0000000000000000000000" title="Share on Facebook">
											<i className="fa fa-facebook"></i>
										</a>
										<a href="0000000000000000000000" title="Share on Twitter">
											<i className="fa fa-twitter"></i>
										</a>
										<a href="0000000000000000000000" title="Share on Instagram">
											<i className="fa fa-instagram"></i>
										</a>
										<a href="0000000000000000000000" title="Share on Facebook">
											<i className="fa fa-linkedin"></i>
										</a>
										<a href="0000000000000000000000" title="Share on Email">
											<i className="fa fa-envelope"></i>
										</a>
									</div>
								</div>

								<div className="inner1">
								
									<a href="0000000000000000000000" className="title" title="5 Rooms luxury appartment in DHA Phase 8, Karachi">
										5 Rooms luxury appartment in DHA Phase 8, Karachi
									</a>

									<p className="loc1">
										<i className="fa fa-map-marker fs13"></i>
										<span>Karachi, Pakistan</span>
									</p>

								</div> 

								<div className="inner2 inner1">

									<div className="flex proplistFeats ai jcb">
										<div className="cont" title="Beds">
											<img src="img/propimg1.png"/>
											<span>
												4
											</span>
										</div>
										<div className="cont" title="Bathrooms">
											<img src="img/propimg2.png"/>
											<span>
												2
											</span>
										</div>
										<div className="cont" title="Garage">
											<img src="img/propimg3.png"/>
											<span>
												1
											</span>
										</div>
										<div className="cont" title="Bathrooms">
											<img src="img/propimg4.png"/>
											<span>
												240 Sq/feets
											</span>
										</div>
									</div>

								</div>


								<div className="inner2 inner1">
									<div className="flex propListFlex ai">

											<a href="0000000000000000000000" title="HR Properties">
												<img src="img/agent10.png" className="agentimg"/>
											</a>

											&nbsp;&nbsp;&nbsp;

											<a href="0000000000000000000000" className="btns btn2">
												View Details
											</a>

											&nbsp;&nbsp;&nbsp;

											<a href="0000000000000000000000" className="btns btn1" title="Call agent">
												<i className="fa fa-phone"></i>
											</a>

									</div>
								</div>


							</div>
						</div> 

						<div className="col-lg-4">
							<div className="propertyGridBoxx featPropList">

								<div className="thumbBack">
									<Slider
										dots={true}
										arrows={true}
										className="slider-list-img"
									>
										<div className="item">
											<img src="img/prop2.png" alt="Property 2" />
										</div>
										<div className="item">
											<img src="img/prop1.png" alt="Property 1" />
										</div>
										<div className="item">
											<img src="img/prop3.png" alt="Property 3" />
										</div>
									</Slider>
									<div className="numOfScreens" title="Pictures available of property">
										<i className="fa fa-camera fs13"></i>&nbsp;
										<span>4</span>
									</div>
									<div class="favourite">
										<a 
											href="0000:"
											onMouseEnter={(e) => e.currentTarget.querySelector('i').classList.replace('fa-heart-o', 'fa-heart')}
											onMouseLeave={(e) => e.currentTarget.querySelector('i').classList.replace('fa-heart', 'fa-heart-o')}
										>
											<i className="fa fa-heart-o"></i>
										</a>
									</div>
									<div className="propSocThuBack flex">
										<a href="0000000000000000000000" title="Share on Facebook">
											<i className="fa fa-facebook"></i>
										</a>
										<a href="0000000000000000000000" title="Share on Twitter">
											<i className="fa fa-twitter"></i>
										</a>
										<a href="0000000000000000000000" title="Share on Instagram">
											<i className="fa fa-instagram"></i>
										</a>
										<a href="0000000000000000000000" title="Share on Facebook">
											<i className="fa fa-linkedin"></i>
										</a>
										<a href="0000000000000000000000" title="Share on Email">
											<i className="fa fa-envelope"></i>
										</a>
									</div>
								</div>

								<div className="inner1">
								
									<a href="0000000000000000000000" className="title" title="5 Rooms luxury appartment in DHA Phase 8, Karachi">
										5 Rooms luxury appartment in DHA Phase 8, Karachi
									</a>

									<p className="loc1">
										<i className="fa fa-map-marker fs13"></i>
										<span>Karachi, Pakistan</span>
									</p>

								</div> 

								<div className="inner2 inner1">

									<div className="flex proplistFeats ai jcb">
										<div className="cont" title="Beds">
											<img src="img/propimg1.png"/>
											<span>
												4
											</span>
										</div>
										<div className="cont" title="Bathrooms">
											<img src="img/propimg2.png"/>
											<span>
												2
											</span>
										</div>
										<div className="cont" title="Garage">
											<img src="img/propimg3.png"/>
											<span>
												1
											</span>
										</div>
										<div className="cont" title="Bathrooms">
											<img src="img/propimg4.png"/>
											<span>
												240 Sq/feets
											</span>
										</div>
									</div>

								</div>


								<div className="inner2 inner1">
									<div className="flex propListFlex ai">

											<a href="0000000000000000000000" title="HR Properties">
												<img src="img/agent10.png" className="agentimg"/>
											</a>

											&nbsp;&nbsp;&nbsp;

											<a href="0000000000000000000000" className="btns btn2">
												View Details
											</a>

											&nbsp;&nbsp;&nbsp;

											<a href="0000000000000000000000" className="btns btn1" title="Call agent">
												<i className="fa fa-phone"></i>
											</a>

									</div>
								</div>


							</div>
						</div> 

						<div className="col-lg-4">
							<div className="propertyGridBoxx featPropList">

								<div className="thumbBack">
									<Slider
										dots={true}
										arrows={true}
										className="slider-list-img"
									>
										<div className="item">
											<img src="img/prop2.png" alt="Property 2" />
										</div>
										<div className="item">
											<img src="img/prop1.png" alt="Property 1" />
										</div>
										<div className="item">
											<img src="img/prop3.png" alt="Property 3" />
										</div>
									</Slider>
									<div className="numOfScreens" title="Pictures available of property">
										<i className="fa fa-camera fs13"></i>&nbsp;
										<span>4</span>
									</div>
									<div className="favourite">
										<a 
											href="0000:"
											onMouseEnter={(e) => e.currentTarget.querySelector('i').classList.replace('fa-heart-o', 'fa-heart')}
											onMouseLeave={(e) => e.currentTarget.querySelector('i').classList.replace('fa-heart', 'fa-heart-o')}
										>
											<i className="fa fa-heart-o"></i>
										</a>
									</div>
									<div className="propSocThuBack flex">
										<a href="0000000000000000000000" title="Share on Facebook">
											<i className="fa fa-facebook"></i>
										</a>
										<a href="0000000000000000000000" title="Share on Twitter">
											<i className="fa fa-twitter"></i>
										</a>
										<a href="0000000000000000000000" title="Share on Instagram">
											<i className="fa fa-instagram"></i>
										</a>
										<a href="0000000000000000000000" title="Share on Facebook">
											<i className="fa fa-linkedin"></i>
										</a>
										<a href="0000000000000000000000" title="Share on Email">
											<i className="fa fa-envelope"></i>
										</a>
									</div>
								</div>

								<div className="inner1">
								
									<a href="0000000000000000000000" className="title" title="5 Rooms luxury appartment in DHA Phase 8, Karachi">
										5 Rooms luxury appartment in DHA Phase 8, Karachi
									</a>

									<p className="loc1">
										<i className="fa fa-map-marker fs13"></i>
										<span>Karachi, Pakistan</span>
									</p>

								</div> 

								<div className="inner2 inner1">

									<div className="flex proplistFeats ai jcb">
										<div className="cont" title="Beds">
											<img src="img/propimg1.png"/>
											<span>
												4
											</span>
										</div>
										<div className="cont" title="Bathrooms">
											<img src="img/propimg2.png"/>
											<span>
												2
											</span>
										</div>
										<div className="cont" title="Garage">
											<img src="img/propimg3.png"/>
											<span>
												1
											</span>
										</div>
										<div className="cont" title="Bathrooms">
											<img src="img/propimg4.png"/>
											<span>
												240 Sq/feets
											</span>
										</div>
									</div>

								</div>


								<div className="inner2 inner1">
									<div className="flex propListFlex ai">

											<a href="0000000000000000000000" title="HR Properties">
												<img src="img/agent10.png" className="agentimg"/>
											</a>

											&nbsp;&nbsp;&nbsp;

											<a href="0000000000000000000000" className="btns btn2">
												View Details
											</a>

											&nbsp;&nbsp;&nbsp;

											<a href="0000000000000000000000" className="btns btn1" title="Call agent">
												<i className="fa fa-phone"></i>
											</a>

									</div>
								</div>


							</div>
						</div> 



					</div> 

				</div> 


				<div className="pagination1 listPagination jc">

					<span>
						<i className="fa fa-angle-left"></i>
					</span>

					<a href="0000000000000000000000" className="current">1</a>
					<a href="0000000000000000000000">2</a>
					<a href="0000000000000000000000">3</a>
					<a href="0000000000000000000000">4</a>
					<a href="0000000000000000000000">5</a>
					<a href="0000000000000000000000">6</a>

					<a href="0000000000000000000000">
						<i className="fa fa-angle-right"></i>
					</a>
				</div>



			</div> 














			
			<div className="col-lg-3">

				<div className="propertySideBar">
					

                <div className="widget-heading2 flex ai jcb" style={{ marginTop: '20px' }}>
						
						<h5 className="head">Locations</h5>

						<input type="text" name="location_search" className="inpsearLoc" placeholder="Search location" id="searchInput"/>

					</div>

					<div className="sideBarLocFlex flex fwrap" id="locations">

						<a href="0000000000000000000000">
							Karachi Central
							<span className="amt">
								(14,547)
							</span>
						</a>
						
						<a href="0000000000000000000000">
							Malir
							<span className="amt">
								(47,6547)
							</span>
						</a>
						
						<a href="0000000000000000000000">
							Orangi Town
							<span className="amt">
								(20,241)
							</span>
						</a>
						
						<a href="0000000000000000000000">
							Karachi West
							<span className="amt">
								(8,445)
							</span>
						</a>
						
						<a href="0000000000000000000000">
							Korangi
							<span className="amt">
								(45,778)
							</span>
						</a>
						
						<a href="0000000000000000000000">
							Keamari District
							<span className="amt">
								(13,544)
							</span>
						</a>
						
						<a href="0000000000000000000000">
							SITE Town
							<span className="amt">
								(54,788)
							</span>
						</a>
						
						<a href="0000000000000000000000">
							Gulshan Town
							<span className="amt">
								(54,125)
							</span>
						</a>
						
						<a href="0000000000000000000000">
							SITE Town
							<span className="amt">
								(45,788)
							</span>
						</a>
						
						<a href="0000000000000000000000">
							Clifton
							<span className="amt">
								(41,478)
							</span>
						</a>
						
						<a href="0000000000000000000000">
							Gulzar-e-Hijri
							<span className="amt">
								(20,145)
							</span>
						</a>
						
						<a href="0000000000000000000000">
							Faisal Colony
							<span className="amt">
								(1,542)
							</span>
						</a>
						
						<a href="0000000000000000000000">
							Manghopir
							<span className="amt">
								(16,440)
							</span>
						</a>

						<a href="0000000000000000000000">
							SITE Town
							<span className="amt">
								(54,788)
							</span>
						</a>
						
						<a href="0000000000000000000000">
							Gulshan Town
							<span className="amt">
								(17,578)
							</span>
						</a>
						
						<a href="0000000000000000000000">
							SITE Town
							<span className="amt">
								(45,788)
							</span>
						</a>
						
						<a href="0000000000000000000000">
							Clifton
							<span className="amt">
								(41,478)
							</span>
						</a>
						
						<a href="0000000000000000000000">
							Gulzar-e-Hijri
							<span className="amt">
								(20,145)
							</span>
						</a>
						
						<a href="0000000000000000000000">
							Faisal Colony
							<span className="amt">
								(1,542)
							</span>
						</a>
					
					</div>


					<div id="noResults" style={{ display: 'none' }}>
						<p className="fw3">Can't find your location?</p>
						<a href="0000000000000000000000" className="csecond underline">Contact us</a>
						<br/><br/>
					</div>


					<hr style={{ margin: '20px 0 25px' }}/>

					<div className="flex ai jcb widget-heading3">
						<h5 className="">Agents</h5>
						<a href="0000000000000000000000" className="fs12 csecond underline">All agents</a>
					</div>


					<div className="row">

						<div className="col-lg-4 col-6">
							<a href="0000000000000000000000" className="agentSideImg">
								<img src="img/agent10.png"/>
							</a>
						</div>
						<div className="col-lg-4 col-6">
							<a href="0000000000000000000000" className="agentSideImg">
								<img src="img/agent8.png"/>
							</a>
						</div>
						<div className="col-lg-4 col-6">
							<a href="0000000000000000000000" className="agentSideImg">
								<img src="img/agent7.png"/>
							</a>
						</div>
						<div className="col-lg-4 col-6">
							<a href="0000000000000000000000" className="agentSideImg">
								<img src="img/agent6.png"/>
							</a>
						</div>
						<div className="col-lg-4 col-6">
							<a href="0000000000000000000000" className="agentSideImg">
								<img src="img/agent5.png"/>
							</a>
						</div>
						<div className="col-lg-4 col-6">
							<a href="0000000000000000000000" className="agentSideImg">
								<img src="img/agent4.png"/>
							</a>
						</div>
						<div className="col-lg-4 col-6">
							<a href="0000000000000000000000" className="agentSideImg">
								<img src="img/agent3.png"/>
							</a>
						</div>
						<div className="col-lg-4 col-6">
							<a href="0000000000000000000000" className="agentSideImg">
								<img src="img/agent2.png"/>
							</a>
						</div>
						<div className="col-lg-4 col-6">
							<a href="0000000000000000000000" className="agentSideImg">
								<img src="img/agent1img.png"/>
							</a>
						</div>

					</div> 


					<hr style={{ margin: '20px 0 25px' }}/>

					<div className="00000000000000">
						<h2 className="fw3 fs20 mb15">
							Get Property Alerts
						</h2>
						<p className="fs13 cgray">
							Enter your email for property alerts. We don’t spam and keep your info private. Find your perfect home faster!
						</p>
						<form method="POST" action="0000000000000000000000">
							<div className="newslEmBack rel flex ai">
								<img src="img/envelopimg2.png" className="img"/>
								<input type="text" name="newsletter" className="newslInp1" placeholder="Email Address"/>
							</div>
							<button className="btn1 newslBtn1">Submit</button>
						</form>
					</div>





				</div> 
				





			</div> 

		</div> 

	</div>
</div>  

    </div> 
    );
}
 
export default List;