import { useState , useRef } from "react";

const AddProperty = () => {

    const [filesArray, setFilesArray] = useState([]);
    const dropAreaRef = useRef(null);
    const fileInputRef = useRef(null);
    const maxImages = 6;
  
    const handleDragOver = (event) => {
      event.preventDefault();
      dropAreaRef.current.classList.add('dragging');
    };
  
    const handleDragLeave = () => {
      dropAreaRef.current.classList.remove('dragging');
    };
  
    const handleDrop = (event) => {
      event.preventDefault();
      dropAreaRef.current.classList.remove('dragging');
      const files = Array.from(event.dataTransfer.files);
      handleFiles(files);
    };
  
    const handleFiles = (files) => {
      if (filesArray.length + files.length > maxImages) {
        alert(`You can only upload a maximum of ${maxImages} images.`);
        return;
      }
  
      const newFilesArray = [...filesArray];
  
      files.forEach(file => {
        if (file.type.startsWith('image/')) {
          newFilesArray.push(file);
        } else {
          alert('Only image files are allowed.');
        }
      });
  
      setFilesArray(newFilesArray);
    };
  
    const handleFileInputChange = (event) => {
      const files = Array.from(event.target.files);
      handleFiles(files);
    };
  
    const removeImage = (fileToRemove) => {
      const newFilesArray = filesArray.filter(file => file !== fileToRemove);
      setFilesArray(newFilesArray);
    };



    return ( 
    <div className="addPropertyClass">
        
<div class="addProperty bg1">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="heading1 flex ai">
                    <img src="img/addhomeimg.png"/>
                    <h2>
                        Add Property
                    </h2>
                </div>
                <br/><br/>
            </div> 


            <div class="col-lg-12">
                
                <div class="block1 mb30">

                    <h2 class="block-head">
                        Upload pictures
                    </h2>

                        <div class="inner">
                            
                                <div class="row ai">

                                    <div class="col-lg-4">
                                        <div class="uploadImg" align="center">

<div
                                                id="drop-area"
                                                ref={dropAreaRef}
                                                onDragOver={handleDragOver}
                                                onDragLeave={handleDragLeave}
                                                onDrop={handleDrop}
                                                onClick={() => fileInputRef.current.click()}
                                                className="drop-area"
                                            >
                                            <img src="img/imageimg.png"/>
                                                <p id="drop-text">{filesArray.length > 0 ? 'Change images' : 'Drag & Drop Images Here'}</p>
                                                <input
                                                id="fileInput"
                                                ref={fileInputRef}
                                                type="file"
                                                multiple
                                                style={{ display: 'none' }}
                                                onChange={handleFileInputChange}
                                                />
                                            </div>
                                            <div id="gallery" className="gallery">
                                                {filesArray.map((file, index) => {
                                                const imgUrl = URL.createObjectURL(file);
                                                return (
                                                    <div key={index} className="image-preview">
                                                    <img src={imgUrl} alt={`preview-${index}`} className="preview-image" />
                                                    <button className="remove-button" onClick={() => removeImage(file)}>×</button>
                                                    </div>
                                                );
                                                })}
                                            </div>

                                        </div>
                                    </div>

                                    <div class="col-lg-4">
                                        <label class="upldImgBtn btn1">
                                            <img src="img/44289.png"/>
                                            <label>Upload</label>
                                            {/* <input
                                                type="file"
                                                ref={fileInputRef}
                                                style={{ display: 'none' }}
                                                onChange={handleFileChange}
                                                id="fileInput"
                                            /> */}
                                            
                                        </label>

                                        <p class="cgray mt20 fs13">
                                            You can upload upto 6 images for one property.
                                            <br/>
                                            <b>Supported file types:</b> JPEG, PNG, GIF.
                                        </p>
                                    </div>

                                    <div class="col-lg-4">
                                            <div id="gallery" class="gallery"></div>
                                    </div>

                                </div>

                        </div> 

                    </div> 


                        <div class="row">

                            <div class="col-lg-3">

                                <div class="block1 mb30">

                                    <h2 class="block-head">
                                        Property Type
                                    </h2>

                                    <div class="inner">
                                        
                                        <div class="flex propTypChek us">

                                            <label class="cont">
                                                <input type="radio" name="type" value="rent" class="form-check-input"/>
                                                Rent
                                            </label>
                                            <label class="cont">
                                                <input type="radio" name="type" value="sell" class="form-check-input"/>
                                                Sell
                                            </label>
                                            <label class="cont">
                                                <input type="radio" name="type" value="lease" class="form-check-input"/>
                                                Lease
                                            </label>

                                        </div>

                                    </div>


                                </div> 




                                <div class="block1 mb30">

                                    <h2 class="block-head">
                                        Features
                                    </h2>

                                    <div class="inner">

                                        <label class="lbl2">Bedrooms</label>
                                        <input type="text" name="bedrooms" class="inp2"/>

                                        <label class="lbl2">Restrooms</label>
                                        <input type="text" name="restrooms" class="inp2"/>
                                        
                                        <label class="lbl2">Garage</label>
                                        <input type="text" name="garage" class="inp2"/>
                                        
                                        <label class="lbl2">Property Area</label>
                                        <input type="text" name="property area" class="inp2"/>
                                        
                                    </div>


                                </div> 


                            </div> 

                            <div class="col-lg-6">
                               
                                <div class="block1 mb30">
                                    <h2 class="block-head">
                                        Post Information
                                    </h2>

                                    <div class="inner">

                                        <label class="lbl2">Title</label>
                                        <input type="text" name="title" class="inp2"/>

                                        <label class="lbl2">Location</label>
                                        <input type="text" name="location" class="inp2"/>

                                        <label class="lbl2">Description</label>
                                        <textarea name="description" class="inp2 textarea1"></textarea>

                                        <label class="lbl2">Location</label>
                                        <input type="text" name="location" class="inp2" placeholder="Google map URL"/>

                                    </div>

                                </div> 

                            </div>    

                            <div class="col-lg-3">
                               
                                <div class="block1 mb30">
                                    <h2 class="block-head">
                                        Pricing & Contact
                                    </h2>

                                    <div class="inner">

                                        <label class="lbl2">Amount</label>
                                        <input type="number" name="number" class="inp2"/>

                                        <label class="lbl2">Currency</label>
                                        <input type="text" name="currency" class="inp2" value="PKR"/>

                                        <hr/> 

                                        <label class="lbl2">Phone number</label>
                                        <input type="text" name="number" class="inp2" value="0300-12134567"/>

                                        <label class="lbl2">Email Address</label>
                                        <input type="email" name="number" class="inp2" value="contact@hr.assosiates.com"/>




                                    </div>

                                </div>

                                <button class="btn1 addPropBtn">
                                    <i class="fa fa-plus fs12"></i>
                                    &nbsp;
                                    Add Property
                                </button>


                            </div>    
                        </div> 


                </div> 





            </div> 

        </div>

    </div>

</div> 
    );
}
 
export default AddProperty;