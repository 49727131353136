const Dashboard = () => {
    return ( 
        <div className="dashboardClass">
            <div class="dashCont bg1 padder1">
    <div class="container">
        
        <div class="row">
            

            <div class="col-lg-3">
                
                <div class="dashNavBack">
                    
                    <h2 class="heading">
                        General
                    </h2>

                    <ul class="leftNavUl">
                        <li>
                            <a href="0000000000000000000000">
                                <span>
                                    <img src="img/dashboardimg1.png" class="navImg"/>
                                    Summery
                                </span>
                            </a>
                        </li>
                        <li>
                            <a href="0000000000000000000000">
                                <span>
                                    <img src="img/messageimg.png" class="navImg"/>
                                    Messages
                                </span>
                                <span class="navTag bg-color1">
                                    15
                                </span>
                            </a>
                        </li>
                        <li>
                            <a href="0000000000000000000000">
                                <span>
                                    <img src="img/booking456.png" class="navImg"/>
                                    Bookings
                                </span>
                                <span class="navTag bg-color2">
                                    2
                                </span>
                            </a>
                        </li>
                        <li>
                            <a href="0000000000000000000000">
                                <span>
                                    <img src="img/settingimg1.png" class="navImg"/>
                                    Settings
                                </span>
                            </a>
                        </li>
                    </ul>
                    
                    <h2 class="heading">
                        Properties
                    </h2>

                    <ul class="leftNavUl">
                        <li>
                            <a href="0000000000000000000000">
                                <span>
                                    <img src="img/addpropimg.png" class="navImg"/>
                                    My properties
                                </span>
                                <span class="navTag bg-color4">
                                    42
                                </span>
                            </a>
                        </li>
                        <li>
                            <a href="0000000000000000000000">
                                <span>
                                    <img src="img/myporpimg.png" class="navImg"/>
                                    Add property
                                </span>
                                <span class="dashNavInfoBack">
                                    <i class="fa fa-info"></i>
                                    <span class="dashTooltip">Add new properties using this option</span>
                                </span>
                            </a>
                        </li>
                        <li>
                            <a href="0000000000000000000000">
                                <span>
                                    <img src="img/analyticsimg.png" class="navImg"/>
                                    Analytics
                                </span>
                                <span class="navTag bg-color3">
                                    New
                                </span>
                            </a>
                        </li>
                    </ul>

                    <h2 class="heading">
                        Profile
                    </h2>

                    <ul class="leftNavUl">
                        <li>
                            <a href="0000000000000000000000">
                                <span>
                                    <img src="img/editprog12.png" class="navImg"/>
                                    Edit profile
                                </span>
                            </a>
                        </li>
                        <li>
                            <a href="0000000000000000000000">
                                <span>
                                    <img src="img/helpimg23.png" class="navImg"/>
                                    Support
                                </span>
                            </a>
                        </li>
                        <li>
                            <a href="0000000000000000000000">
                                <span>
                                    <img src="img/logsimg.png" class="navImg"/>
                                    Logs
                                </span>
                            </a>
                        </li>
                        <li>
                            <a href="0000000000000000000000">
                                <span>
                                    <img src="img/logoutimg.png" class="navImg"/>
                                    Sign out
                                </span>
                            </a>
                        </li>
                    </ul>


                    <div class="dashNavSpec">
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod empor incididunt ut labore et
                        </p>
                        <br/>
                        <a href="0000000000000000000000" class="underline cgray">Report a bug</a>
                    </div>



                </div>

            </div> 





            <div class="col-lg-9">
                
                <div class="dashTitleBar">
                    
                    <div class="cont">
                        <h2 class="dashPageTitle">Dashboard</h2>
                        <p class="desc">
                            Your summery will be visible here
                        </p>
                    </div>

                    <div class="flex ai">
                        <a href="0000000000000000000000" class="btn1 btnpad1">
                            <i class="fa fa-plus"></i> 
                            &nbsp;
                            Add property
                        </a>
                    </div>


                </div>








                <div class="dashStatsCont">
                    <div class="row">
                        
                        <div class="col-lg-3">

                            <div class="dashStatBox">

                                <div class="flex">
                                    <div class="cont">
                                        <h2 class="title">Properties</h2>
                                        <p class="val">
                                            23
                                        </p>
                                    </div>
                                    <div class="cont">
                                        <div class="imgBack">
                                            <img src="img/prop123.png"/>
                                        </div>
                                    </div>
                                </div>

                            </div> 

                        </div> 
                        <div class="col-lg-3">

                            <div class="dashStatBox">

                                <div class="flex">
                                    <div class="cont">
                                        <h2 class="title">Bookings</h2>
                                        <p class="val">
                                            43
                                        </p>
                                    </div>
                                    <div class="cont">
                                        <div class="imgBack">
                                            <img src="img/booking415.png"/>
                                        </div>
                                    </div>
                                </div>

                            </div> 

                        </div> 
                        <div class="col-lg-3">

                            <div class="dashStatBox">

                                <div class="flex">
                                    <div class="cont">
                                        <h2 class="title">Messages</h2>
                                        <p class="val">
                                            18
                                        </p>
                                    </div>
                                    <div class="cont">
                                        <div class="imgBack">
                                            <img src="img/msg154.png"/>
                                        </div>
                                    </div>
                                </div>

                            </div> 

                        </div> 
                        <div class="col-lg-3">

                            <div class="dashStatBox">

                                <div class="flex">
                                    <div class="cont">
                                        <h2 class="title">Total Payments</h2>
                                        <p class="val">
                                            20,457,200
                                        </p>
                                    </div>
                                    <div class="cont">
                                        <div class="imgBack">
                                            <img src="img/cc545.png"/>
                                        </div>
                                    </div>
                                </div>

                            </div> 

                        </div> 

                    </div>
                </div> 







                <div class="dash-block">
                    

                    <div class="dash-block-heading">
                        <h2 class="head">
                            Table Style
                        </h2>
                        <a href="0000000000000000000000" class="link">All bookings</a>
                    </div> 


                    <div class="dash-table-cont">

                        <table class="dash-table">

                            <tr>
                                <th>Applicant</th>
                                <th>Amount</th>
                                <th>Date</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>

                            <tr>
                                <td>
                                    <div class="dashUserBox1">
                                        <div class="cont">
                                            <img src="img/person1.png" class="img"/>
                                        </div>
                                        <div class="cont">
                                            <p class="name">Muhammad Kamran</p>
                                            <p class="email">user-email@gmail.com</p>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <p>200,2000,2000 PKR</p>
                                </td>
                                <td>
                                    <p>20-Jan-2024</p>
                                </td>
                                <td>
                                    <span class="tag tag-blue">Pending</span>
                                </td>
                                <td>
                                    <a href="0000000000000000000000" class="table-btns btn-green"><i class="fa fa-eye"></i> View</a>
                                    <a href="0000000000000000000000" class="table-btns btn-blue"><i class="fa fa-edit"></i> Edit</a>
                                    <a href="0000000000000000000000" class="table-icon-btns btn-red"><i class="fa fa-times"></i></a>
                                </td>
                            </tr>


                        </table>

                    </div> 


                </div>  







                <div class="dash-block">
                    

                    <div class="dash-block-heading">
                        <h2 class="head">
                            All Assets
                        </h2>
                    </div> 



                    <h2 class="fw3 fs14">Buttons</h2>
                    <p class="fs13 cgray">
                        Add a class "btns" and the type of button ex. "btn-primary". So the class will be "btns btn-primary". Add the class"m0" to remove the margin around button.
                    </p> 
                    <br/>

                    <span class="btns btn-primary">btn-primary</span>
                    <span class="btns btn-secondary">btn-secondary</span>
                    <span class="btns btn-gray">btn-gray</span>
                    <span class="btns btn-red">btn-red</span>
                    <span class="btns btn-blue">btn-blue</span>
                    <span class="btns btn-green">btn-green</span>
                    <span class="btns btn-orange">btn-orange</span>



                    <hr class="m20" style={{opacity: " 0.2"}}/>



                    <h2 class="fw3 fs14">Tags</h2>
                    <p class="fs13 cgray">
                        Add a class "tag" and the type of button ex. "tag-primary". So the class will be "tag tag-primary". 
                    </p> 
                    <br/>

                    <span class="tag tag-primary">tag-primary</span>
                    <span class="tag tag-secondary">tag-secondary</span>
                    <span class="tag tag-gray">tag-gray</span>
                    <span class="tag tag-red">tag-red</span>
                    <span class="tag tag-blue">tag-blue</span>
                    <span class="tag tag-green">tag-green</span>
                    <span class="tag tag-orange">tag-orange</span>



                    <hr class="m20" style={{opacity: "0.2"}}/>



                    <h2 class="fw3 fs14">Pills</h2>
                    <p class="fs13 cgray">
                        Add a class "pill" and the type of button ex. "pill-primary". So the class will be "pill pill-primary". 
                    </p> 
                    <br/>

                    <span class="pill pill-primary">pill-primary</span>
                    <span class="pill pill-secondary">pill-secondary</span>
                    <span class="pill pill-gray">pill-gray</span>
                    <span class="pill pill-red">pill-red</span>
                    <span class="pill pill-blue">pill-blue</span>
                    <span class="pill pill-green">pill-green</span>
                    <span class="pill pill-orange">pill-orange</span>




                </div>  




                <div class="row">
                    
                    <div class="col-lg-6">


                        <div class="dash-block">
                            

                            <div class="dash-block-heading m0 block">
                                <h2 class="head">
                                    Form
                                </h2>
                                <p class="fs13 cgray">
                                    With Icon inside input field
                                </p>  <br/>

                            </div>


                            <label class="label1">Full name:</label>
                            <div class="inpBack">
                                <i class="fa fa-user-o"></i>
                                <input type="name" name="name" placeholder="Required" class="input2"/>
                            </div>

                            <label class="label1">Message:</label>
                            <div class="inpBack txtInpBack">
                                <i class="fa fa-envelope-o"></i>
                                <textarea name="msg" class="input2 textarea1"></textarea>
                            </div>

                            <label class="checkboxBack">
                                <input type="checkbox" name="checkbox" class="checkbox"/>
                                <span>I agree the <a href="0000000000000000000000">Terms and conditiuons</a> of myproperty.pk</span>
                            </label>

                            <hr class="hr1"/>

                            <button class="btns btn-secondary">
                                Submit
                                &nbsp;
                                <i class="fa fa-angle-right"></i>
                            </button>

                        </div>   



                    </div> 



                    <div class="col-lg-6">


                        <div class="dash-block">
                            

                            <div class="dash-block-heading m0 block">
                                <h2 class="head">
                                    Form
                                </h2>
                                <p class="fs13 cgray">
                                    Without Icon inside input field
                                </p>  <br/>

                            </div>


                            <label class="label1">Full name:</label>
                            <input type="name" name="name" placeholder="Required" class="input1"/>

                            <label class="label1">Message:</label>
                            <textarea name="msg" class="input1 textarea1"></textarea>


                            <label class="checkboxBack">
                                <input type="checkbox" name="checkbox" class="checkbox"/>
                                <span>I agree the <a href="0000000000000000000000">Terms and conditiuons</a> of myproperty.pk</span>
                            </label>


                            <hr class="hr1"/>

                            <button class="btns btn-secondary">
                                Submit
                                &nbsp;
                                <i class="fa fa-angle-right"></i>
                            </button>

                        </div> 



                    </div> 

                </div> 










            </div> 



        </div> 

    </div>
</div>
</div>
     );
}
 
export default Dashboard;