const Agent = () => {
    return ( 
        <div className="agent">
            
<div className="agentListPage padder1">
    <div className="container">
        
        <div className="row">
            

            <div className="col-lg-3">

                <form method="POST" action="0000000000000000000000">
                    
                    <div className="agFilterCont mb20 bg1">
                        
                        <h2 className="sidebarHead">
                            Search agent
                        </h2>

                        <input type="text" name="agent_name" className="input1" placeholder="Agent name" />

                        <h2 className="sidebarHead">
                            City
                        </h2>


                        <select className="input1" name="city">
                            <option value="">All cities</option>
                            <option value="Karachi">Karachi</option>
                            <option value="Lahore">Lahore</option>
                            <option value="Islamabad">Islamabad</option>
                            <option value="Rawalpindi">Rawalpindi</option>
                            <option value="Faisalabad">Faisalabad</option>
                            <option value="Peshawar">Peshawar</option>
                            <option value="Quetta">Quetta</option>
                            <option value="Multan">Multan</option>
                            <option value="Sialkot">Sialkot</option>
                            <option value="Gujranwala">Gujranwala</option>
                            <option value="Hyderabad">Hyderabad</option>
                            <option value="Sukkur">Sukkur</option>
                            <option value="Abbottabad">Abbottabad</option>
                            <option value="Sargodha">Sargodha</option>
                            <option value="Bahawalpur">Bahawalpur</option>
                            <option value="Sahiwal">Sahiwal</option>
                            <option value="Rahim Yar Khan">Rahim Yar Khan</option>
                            <option value="Jhang">Jhang</option>
                            <option value="Dera Ghazi Khan">Dera Ghazi Khan</option>
                            <option value="Muzaffargarh">Muzaffargarh</option>
                        </select>

                        <button className="btnpad1 btn1 btnfull flex ai jc"><i className="fa fa-home fs12"></i> &nbsp; Search agent</button>



                    </div> 
                </form>

            </div>


            <div className="col-lg-9">
                
                <div className="agentIner">
                    

                    <h2 className="widget-heading">
                        Agents
                    </h2>
                    <p className="cgray mt5">
                        Total agents: 2,457
                    </p>

                    <hr className="hr1" />

                    <div className="row">
                        
                        <div className="col-lg-3 col-6">
                            <a href="0000000000000000000000" className="agLisBox">
                                <div className="cont">
                                    <img src="img/agent1img.png" className="agentImg" alt="Agent" />
                                </div>
                                <div className="cont">
                                    <p className="title" title="Al-Asr Enterprises">
                                        Al-Asr Enterprises
                                    </p>
                                    <p className="desc">
                                        Karachi
                                    </p>
                                </div>
                            </a>
                        </div>

                        <div className="col-lg-3 col-6">
                            <a href="0000000000000000000000" className="agLisBox">
                                <div className="cont">
                                    <img src="img/agent1img.png" className="agentImg" alt="Agent" />
                                </div>
                                <div className="cont">
                                    <p className="title" title="Al-Asr Enterprises">
                                        Al-Asr Enterprises
                                    </p>
                                    <p className="desc">
                                        Karachi
                                    </p>
                                </div>
                            </a>
                        </div>

                        <div className="col-lg-3 col-6">
                            <a href="0000000000000000000000" className="agLisBox">
                                <div className="cont">
                                    <img src="img/agent1img.png" className="agentImg" alt="Agent" />
                                </div>
                                <div className="cont">
                                    <p className="title" title="Al-Asr Enterprises">
                                        Al-Asr Enterprises
                                    </p>
                                    <p className="desc">
                                        Karachi
                                    </p>
                                </div>
                            </a>
                        </div>

                        <div className="col-lg-3 col-6">
                            <a href="0000000000000000000000" className="agLisBox">
                                <div className="cont">
                                    <img src="img/agent1img.png" className="agentImg" alt="Agent" />
                                </div>
                                <div className="cont">
                                    <p className="title" title="Al-Asr Enterprises">
                                        Al-Asr Enterprises
                                    </p>
                                    <p className="desc">
                                        Karachi
                                    </p>
                                </div>
                            </a>
                        </div>

                        <div className="col-lg-3 col-6">
                            <a href="0000000000000000000000" className="agLisBox">
                                <div className="cont">
                                    <img src="img/agent1img.png" className="agentImg" alt="Agent" />
                                </div>
                                <div className="cont">
                                    <p className="title" title="Al-Asr Enterprises">
                                        Al-Asr Enterprises
                                    </p>
                                    <p className="desc">
                                        Karachi
                                    </p>
                                </div>
                            </a>
                        </div>

                        <div className="col-lg-3 col-6">
                            <a href="0000000000000000000000" className="agLisBox">
                                <div className="cont">
                                    <img src="img/agent1img.png" className="agentImg" alt="Agent" />
                                </div>
                                <div className="cont">
                                    <p className="title" title="Al-Asr Enterprises">
                                        Al-Asr Enterprises
                                    </p>
                                    <p className="desc">
                                        Karachi
                                    </p>
                                </div>
                            </a>
                        </div>

                        <div className="col-lg-3 col-6">
                            <a href="0000000000000000000000" className="agLisBox">
                                <div className="cont">
                                    <img src="img/agent1img.png" className="agentImg" alt="Agent" />
                                </div>
                                <div className="cont">
                                    <p className="title" title="Al-Asr Enterprises">
                                        Al-Asr Enterprises
                                    </p>
                                    <p className="desc">
                                        Karachi
                                    </p>
                                </div>
                            </a>
                        </div>

                        <div className="col-lg-3 col-6">
                            <a href="0000000000000000000000" className="agLisBox">
                                <div className="cont">
                                    <img src="img/agent1img.png" className="agentImg" alt="Agent" />
                                </div>
                                <div className="cont">
                                    <p className="title" title="Al-Asr Enterprises">
                                        Al-Asr Enterprises
                                    </p>
                                    <p className="desc">
                                        Karachi
                                    </p>
                                </div>
                            </a>
                        </div>

                    </div>



                <div className="pagination1 listPagination jc">

                    <span>
                        <i className="fa fa-angle-left"></i>
                    </span>

                    <a href="0000000000000000000000" className="current">1</a>
                    <a href="0000000000000000000000">2</a>
                    <a href="0000000000000000000000">3</a>
                    <a href="0000000000000000000000">4</a>
                    <a href="0000000000000000000000">5</a>
                    <a href="0000000000000000000000">6</a>

                    <a href="0000000000000000000000">
                        <i className="fa fa-angle-right"></i>
                    </a>
                </div>




                </div>

            </div>


        </div>

    </div>
</div>

        </div>
     );
}
 
export default Agent;