const Blogs_list = () => {
    return ( 
        <div className="Blogs_list">
            <div class="blogListPage">
    
    <div class="blogListSearchBack">
        
        <div class="container">
            
            <div class="row">
                
                <div class="col-lg-12">
                    
                    <div class="bligListSearch">
                        
                        <h2 class="fw3 fs20 mb20">
                            Search Blogs
                        </h2>

                        <div class="flex blogSearchListFlex">

                            <input type="text" name="search" class="input1 m0 searchinp" placeholder="Find a blog..." />    


                            <select name="cat" class="input1 select_inp m0">
                                <option value="">All categories</option>
                                <option value="Properties">Properties</option>
                                <option value="Politics">Politics</option>
                                <option value="Finance">Finance</option>
                                <option value="Investment">Investment</option>
                            </select>


                            <button type="submit" class="btn1 btnpad1 flex ai">
                                <i class="fa fa-search fs12"></i>
                                &nbsp;&nbsp;
                                <span>Search</span>
                            </button>
                        </div>

                    </div>

                </div>

            </div>

        </div>

    </div>

    <div class="blogListBack padder1 recBlogBack blogView ">
        
        <div class="container">

            <h2 class="fw3 fs20 mb20">
                Our Blogs
            </h2>

            <div class="row">
                
                <div class="col-lg-3">

                        <a href="0000000000000" class="recBlogBack">

                            <div class="thumbBack">
                                <img src="img/prop3.png" />
                            </div>

                            <div class="inner2">
                                
                                <h2 class="sideTitle">
                                    Property problems that will keep on occuring soon!
                                </h2>

                                <p class="sideDesc">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                    consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                                    cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                                    proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                </p>

                                <a href="0000000000000000000000" class="bloglistbtn1">Read blog</a>

                            </div>

                        </a>

                </div>

                <div class="col-lg-3">

                        <a href="0000000000000" class="recBlogBack">

                            <div class="thumbBack">
                                <img src="img/prop3.png" />
                            </div>

                            <div class="inner2">
                                
                                <h2 class="sideTitle">
                                    Property problems that will keep on occuring soon!
                                </h2>

                                <p class="sideDesc">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                    consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                                    cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                                    proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                </p>

                                <a href="0000000000000000000000" class="bloglistbtn1">Read blog</a>

                            </div>

                        </a>

                </div>

                <div class="col-lg-3">

                        <a href="0000000000000" class="recBlogBack">

                            <div class="thumbBack">
                                <img src="img/prop3.png" />
                            </div>

                            <div class="inner2">
                                
                                <h2 class="sideTitle">
                                    Property problems that will keep on occuring soon!
                                </h2>

                                <p class="sideDesc">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                    consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                                    cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                                    proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                </p>

                                <a href="0000000000000000000000" class="bloglistbtn1">Read blog</a>

                            </div>

                        </a>

                </div>

                <div class="col-lg-3">

                        <a href="0000000000000" class="recBlogBack">

                            <div class="thumbBack">
                                <img src="img/prop3.png" />
                            </div>

                            <div class="inner2">
                                
                                <h2 class="sideTitle">
                                    Property problems that will keep on occuring soon!
                                </h2>

                                <p class="sideDesc">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                    consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                                    cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                                    proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                </p>

                                <a href="0000000000000000000000" class="bloglistbtn1">Read blog</a>

                            </div>

                        </a>

                </div>

                <div class="col-lg-3">

                        <a href="0000000000000" class="recBlogBack">

                            <div class="thumbBack">
                                <img src="img/prop3.png" />
                            </div>

                            <div class="inner2">
                                
                                <h2 class="sideTitle">
                                    Property problems that will keep on occuring soon!
                                </h2>

                                <p class="sideDesc">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                    consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                                    cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                                    proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                </p>

                                <a href="0000000000000000000000" class="bloglistbtn1">Read blog</a>

                            </div>

                        </a>

                </div>

                <div class="col-lg-3">

                        <a href="0000000000000" class="recBlogBack">

                            <div class="thumbBack">
                                <img src="img/prop3.png" />
                            </div>

                            <div class="inner2">
                                
                                <h2 class="sideTitle">
                                    Property problems that will keep on occuring soon!
                                </h2>

                                <p class="sideDesc">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                    consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                                    cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                                    proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                </p>

                                <a href="0000000000000000000000" class="bloglistbtn1">Read blog</a>

                            </div>

                        </a>

                </div>

                <div class="col-lg-3">

                        <a href="0000000000000" class="recBlogBack">

                            <div class="thumbBack">
                                <img src="img/prop3.png" />
                            </div>

                            <div class="inner2">
                                
                                <h2 class="sideTitle">
                                    Property problems that will keep on occuring soon!
                                </h2>

                                <p class="sideDesc">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                    consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                                    cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                                    proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                </p>

                                <a href="0000000000000000000000" class="bloglistbtn1">Read blog</a>

                            </div>

                        </a>

                </div>

                <div class="col-lg-3">

                        <a href="0000000000000" class="recBlogBack">

                            <div class="thumbBack">
                                <img src="img/prop3.png" />
                            </div>

                            <div class="inner2">
                                
                                <h2 class="sideTitle">
                                    Property problems that will keep on occuring soon!
                                </h2>

                                <p class="sideDesc">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                    consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                                    cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                                    proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                </p>

                                <a href="0000000000000000000000" class="bloglistbtn1">Read blog</a>

                            </div>

                        </a>

                </div>

            </div>
        </div>

                <div class="pagination1 listPagination jc">

                    <span>
                        <i class="fa fa-angle-left"></i>
                    </span>

                    <a href="0000000000000000000000" class="current">1</a>
                    <a href="0000000000000000000000">2</a>
                    <a href="0000000000000000000000">3</a>
                    <a href="0000000000000000000000">4</a>
                    <a href="0000000000000000000000">5</a>
                    <a href="0000000000000000000000">6</a>

                    <a href="0000000000000000000000">
                        <i class="fa fa-angle-right"></i>
                    </a>
                </div>

    </div>

</div>
        </div>
     );
}
 
export default Blogs_list;