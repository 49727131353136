import { Link } from "react-router-dom";
import { useRef, useState, useEffect } from "react";

const Header = () => {

	const [isDropdownVisible, setDropdownVisible] = useState(false);
    const containerRef = useRef(null);

    const toggleDropdown = () => {
        setDropdownVisible(!isDropdownVisible);
    };

    const handleClickOutside = (event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setDropdownVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mouseup', handleClickOutside);
        return () => {
            document.removeEventListener('mouseup', handleClickOutside);
        };
    }, []);
    return ( 
        <div className="headerclassName">
             <div className="topHeader">
                <div className="container">
                    <div className="row">
                        
                        <div className="col-lg-6">
                            <Link to="/addproperty" className="topLink">ADD PROPERTY</Link>
                            <Link to="/agent" className="topLink">AGENTS</Link>
                            <Link to="/blogs_list" className="topLink">OUR BLOGS</Link>
                            <Link to="/policy" className="topLink">POLICIES</Link>
                        </div>

                        <div className="col-lg-6" align="right">
						<div class="flex langSocialFlex jce">


						<div class="topLangContainer rel"  ref={containerRef}>
							
							<div class="langValBack" onClick={toggleDropdown}>
								<div><img src="img/usa.png" class="flag"/></div>
								<div class="cont">
									<span class="txt">English</span>
								</div>
							</div>

							{isDropdownVisible && (
							<div class="langTopBack ">
								<div class="TopLangSingle active">
									<div><img src="img/usa.png" class="flag"/></div>
									<div class="cont">
										<span class="txt">English</span>
									</div>
								</div>
								<div class="TopLangSingle">
									<div><img src="img/pak.png" class="flag"/></div>
									<div class="cont">
										<span class="txt">Urdu</span>
									</div>
								</div>
								<div class="TopLangSingle">
									<div><img src="img/ind.png" class="flag"/></div>
									<div class="cont">
										<span class="txt">Hindi</span>
									</div>
								</div>
								<div class="TopLangSingle">
									<div><img src="img/ksa.png" class="flag"/></div>
									<div class="cont">
										<span class="txt">Arabic</span>
									</div>
								</div>
								<div class="TopLangSingle">
									<div><img src="img/jpn.png" class="flag"/></div>
									<div class="cont">
										<span class="txt">Japanese</span>
									</div>
								</div>
							</div> 
 							)}

						</div> 


                            <div className="topsocialbox inflex">
                                <Link to="0000000000000000000000">
                                    <i className="fa fa-facebook"></i>
                                </Link>
                                <Link to="0000000000000000000000">
                                    <i className="fa fa-twitter"></i>
                                </Link>
                                <Link to="0000000000000000000000">
                                    <i className="fa fa-instagram"></i>
                                </Link>
                                <Link to="0000000000000000000000">
                                    <i className="fa fa-linkedin"></i>
                                </Link>
                            </div>

						</div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="header">
	<div className="container rel">

		<div className="bars">
			<span></span>
			<span></span>
			<span></span>
		</div>

		<div className="row ai">
			
			<div className="col-lg-2">
				<Link to="/"><img src='/img/logo-main.png' className="logo-main"/></Link>
			</div>

			<div className="col-lg-6">
				<ul className="mainul">
					<li>
						<Link to="/" className="main-link">HOME</Link>
					</li>
					<li>
						<Link to="/find-properties" className="main-link">FIND PROPERTIES</Link>
					</li>
					<li>
						<Link to="/agent" className="main-link">AGENTS</Link>
					</li>
				</ul>
			</div>

			<div className="col-lg-4" align="RIGHT">
				<ul className="mainul">
					<li>
						<Link to="/login" className="main-link"><i className="fa fa-lock fs11"></i>&nbsp; LOG IN</Link>
					</li>
					<li>
						<Link to="/register" className="main-link"><i className="fa fa-user fs11"></i>&nbsp; Register</Link>
					</li>
				</ul>
			</div> 
		</div>
	</div>
</div>
        </div>
     );
}
 
export default Header;