const Footer = () => {
    return ( 
        <div className="footerclassName">
            <div className="footer">
	<div className="container">
		<div className="row">
			
			<div className="col-lg-3">
				<img src="img/whitelogo.png" className="footLogo"/>

				<p className="footDesc">
					Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
					tempor incididunt ut labore et dolore magna aliqu consectetur a. Ut enim ad minim 
				</p>
			</div>

			<div className="col-lg-2 offset-lg-1 col-4">
				<h2 className="footHead">
					General
				</h2>
				<ul className="footList">
					<li>
						<a href="/">Home</a>
					</li>
					<li>
						<a href="/findproperty">Find Properties</a>
					</li>
					<li>
						<a href="/agent">Agents</a>
					</li>
					<li>
						<a href="/blogs_list">Our Blogs</a>
					</li>
				</ul>
			</div>

			<div className="col-lg-2 col-4">
				<h2 className="footHead">
					Cities
				</h2>
				<ul className="footList">
					<li>
						<a href="0000000000000000000000">Karachi</a>
					</li>
					<li>
						<a href="0000000000000000000000">Lahore</a>
					</li>
					<li>
						<a href="0000000000000000000000">Islamabad</a>
					</li>
					<li>
						<a href="0000000000000000000000">Peshawar</a>
					</li>
					<li>
						<a href="0000000000000000000000">More cities &nbsp;<i className="fa fa fa-angle-right'"></i></a>
					</li>
				</ul>
			</div>

			<div className="col-lg-2 col-4">
				<h2 className="footHead">
					Policies
				</h2>
				<ul className="footList">
					<li>
						<a href="/terms">Terms of Service</a>
					</li>
					<li>
						<a href="/policy">Privacy Policy</a>
					</li>
				</ul>
			</div> 

			<div className="col-lg-2">
				<h2 className="footHead">
					Socials
				</h2>
				<div className="footerSocials flex fwrap">
					<a href="00000000000000"><i className="fa fa-facebook"></i></a>
					<a href="00000000000000"><i className="fa fa-twitter"></i></a>
					<a href="00000000000000"><i className="fa fa-linkedin"></i></a>
					<a href="00000000000000"><i className="fa fa-instagram"></i></a>
					<a href="00000000000000"><i className="fa fa-whatsapp"></i></a>
					<a href="00000000000000"><i className="fa fa-envelope"></i></a>
				</div>
			</div>

		</div>
	</div>


	<div className="copyrightFoot">
		
		<div className="container">
			<div className="row">
				
				<div className="col-lg-6">
					<p>
						All rights reserved by myproperty.pk 2024 &copy;
					</p>
				</div>

				<div className="col-lg-6" align="right">
					<a href="0000000000000000000000">Get help</a>
					<span style={{margin: 10 +'px'}}>|</span>
					<a href="0000000000000000000000">Contact us</a>
				</div>

			</div>
		</div> 

	</div>


</div> 
        </div>
     );
}
 
export default Footer;