import './App.css';
import Footer from './pages/footer';
import Header from './pages/header';
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom';
import Home from './pages/home';
import ErrorPage from './pages/404';
import Policy from './pages/policy';
import Dashboard from './pages/dashboard';
import Dashboard_table from './pages/dashboard_table';
import AddProperty from './pages/addProperty';
import List from './pages/list';
import Single from './pages/single';
import Agent from './pages/agent';
import Login from './pages/login';
import Register from './pages/register'; 
import Blogs from './pages/blogs';
import Blogs_list from './pages/blogs_list';
import Terms from './pages/terms';



function AppContent() {
  const location = useLocation();
  const hideHeaderFooter = location.pathname === '/login' || location.pathname === '/register';

  return (
    <div className="App">
      {!hideHeaderFooter && <Header />}
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/policy">
          <Policy />
        </Route>
        <Route exact path="/dashboard">
          <Dashboard />
        </Route>
        <Route exact path="/dashboard_table">
          <Dashboard_table />
        </Route>
        <Route exact path="/addproperty">
          <AddProperty />
        </Route>
        <Route exact path="/find-properties">
          <List />
        </Route>
        <Route exact path="/property">
          <Single />
        </Route>
        <Route exact path="/agent">
          <Agent />
        </Route>
        <Route  exact path="/single">
          <Single/>
        </Route>
        <Route exact path="/terms">
          <Terms/>
        </Route>

        <Route exact path="/login">
          <Login />
        </Route>
        <Route exact path="/register">
          <Register />
        </Route>
        <Route exact path="/blogs">
          <Blogs />
        </Route>
        <Route exact path="/blogs_list">
          <Blogs_list />
        </Route>
        <Route exact path="*">
          <ErrorPage />
        </Route>
      </Switch>
      {!hideHeaderFooter && <Footer />}
    </div>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
