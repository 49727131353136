const Terms = () => {
    return ( 
    <div className="termsClass">
        <div className="container">
            <h1 className="text-center my-5">Terms and Conditions</h1>
            <div className="row">
                <div className="col-lg-8 mx-auto">
                    <h2>1. Acceptance of Terms</h2>
                    <p>By accessing and using this website, you accept and agree to be bound by the terms and provision of this agreement.</p>

                    <h2>2. Use of Website</h2>
                    <p>You agree to use the website for lawful purposes only and in a way that does not infringe the rights of, restrict or inhibit anyone else's use and enjoyment of the website.</p>

                    <h2>3. Intellectual Property</h2>
                    <p>All content included on this site, such as text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, and software, is the property of the website owner or its content suppliers and protected by international copyright laws.</p>

                    <h2>4. Limitation of Liability</h2>
                    <p>The website owner shall not be liable for any damages arising out of or in connection with the use or performance of this site, or the information contained within.</p>

                    <h2>5. Changes to Terms</h2>
                    <p>We reserve the right to modify these terms at any time. Please review these terms periodically for changes.</p>
                </div>
            </div>
        </div>

    </div> 
    );
}
 
export default Terms;