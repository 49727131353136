import { useRef } from "react";
import Slider from "react-slick";


const Home = () => {

    const slickContainerRef = useRef(null);

    const handleNextClick = () => {
      // Trigger the slick-next button click
      slickContainerRef.current.querySelector(".latBtn-next").click();
    };
  
    const handlePrevClick = () => {
      // Trigger the slick-prev button click
      slickContainerRef.current.querySelector(".latBtn-prev").click();
    };
  

    var settings = {
        infinite: false,
		speed: 300,
		slidesToShow: 4,
		slidesToScroll: 1,
		dots: false,
		nextArrow: <div className="latBtn-next"><i className="fa fa-angle-right latBtn-next latpropHomBtn"></i></div>,	
    	prevArrow: <div className="latBtn-prev"><i className="fa fa-angle-left latBtn-prev latpropHomBtn"></i></div>,
		responsive: [
		{
			breakpoint: 1024,
			settings: {
			slidesToShow: 3,
			slidesToScroll: 3,
			infinite: true,
			dots: true
		}
		},
		{
			breakpoint: 600,
			settings: {
			slidesToShow: 2,
			slidesToScroll: 2
		}
		},
		{
			breakpoint: 480,
			settings: {
			slidesToShow: 1,
			slidesToScroll: 1
		}
		}

		]
      };
    return ( 
        <div class="homeclass">
            
<div class="heroSec1">
	<div class="inner">
		
		<div class="container">
			<div class="row ai jc">
				
				<div class="col-lg-7" align="center">
					<form method="POST" action="0000000000000000000000">
						
						<h2 class="heroTitle1">
							Buy, Rent or Sell Your Property!
						</h2>

						<div class="HomeSearchBig">
							
							<div class="flex  jcb ai">
								
								<div class="flex heroSearOpt1">
									<label class="searFiltTypeOpt active">
										<input type="hidden" name="search-type" value="buy"/>
										BUY
									</label>
									<label class="searFiltTypeOpt">
										<input type="hidden" name="search-type" value="rent"/>
										RENT
									</label>
									<label class="searFiltTypeOpt">
										<input type="hidden" name="search-type" value="sell"/>
										SELL
									</label>
								</div> 

								<span class="advSearBtn11">Advance</span>


							</div>


							<div class="heroSearSec2">
								<div class="flex mainHeroSearch">
									
								<div class="homSearchBack">

										<input type="text" name="location" placeholder="Location / City" class="searHeroInp1 locationinp"/>

										<ul class="homSuggestList">
											<li>
												Orangi Town, Karachi, Sindh
											</li>
											<li>
												Clifton Block 8, Karachi, Sindh
											</li>
											<li>
												Korangi No. 4, Sindh
											</li>
											<li>
												3 Talwar, Sindh
											</li>
											<li>
												Minar-e-Pakistan, Lahore, Punjab
											</li>
										</ul>

										</div>

										<button type="submit" class="btn1 herosearhBtn">
											<i class="fa fa-search fs11"></i>
											&nbsp;
											<span class="findTxt">Find</span>
										</button>
								</div>



							</div> 


							<div class="advSearBack">
								<div class="row">

									<div class="col-lg-3">
										<label class="lbl">Property Type</label>
										<select class="inpadvHome" name="prop_type">
											<option value="House">House</option>
											<option value="Flat">Flat</option>
											<option value="Apartment">Apartment</option>
											<option value="Office">Office</option>
											<option value="Shop">Shop</option>
										</select>
									</div>

									<div class="col-lg-3">
										<label class="lbl">Price (PKR)</label>
										<div class="flex pricHomAdvFlex">
											<input type="number" name="price_min" class="inpadvHome" placeholder="Min."/>
											&nbsp;&nbsp;&nbsp;
											<input type="number" name="price_max" class="inpadvHome" placeholder="Max."/>
										</div>
									</div>

									<div class="col-lg-3">
										<label class="lbl">AREA (Sq. Yd.)</label>
										<div class="flex pricHomAdvFlex">
											<input type="number" name="price_min" class="inpadvHome" placeholder="Min."/>
											&nbsp;&nbsp;&nbsp;
											<input type="number" name="price_max" class="inpadvHome" placeholder="Max."/>
										</div>
									</div>

									<div class="col-lg-3">
										<label class="lbl">beds</label>
										<select class="inpadvHome" name="prop_type">
											<option value="1">1</option>
											<option value="2">2</option>
											<option value="3">3</option>
											<option value="4">4</option>
											<option value="5">5</option>
										</select>
									</div>

								</div>
							</div> 


						</div> 



						<div class="searBellowPiulls">
							<div class="flex fwrap jc">

								<a href="0000000000000000000000">Karachi</a>
								<a href="0000000000000000000000">Lahore</a>
								<a href="0000000000000000000000">Islamabad</a>
								<a href="0000000000000000000000">Faisalabad</a>
								<a href="0000000000000000000000">Multan</a>
								<a href="0000000000000000000000">Quetta</a>
								<a href="0000000000000000000000">Larkana</a>
								
							</div>
						</div>



					</form>
				</div> 

			</div>
		</div>

	</div>

</div> 




<div class="bottomHeroBox">
	<div class="container">
		<div class="row">
			
			<div class="col-lg-3">
				<div class="btmerFlex flex">
					<div class="cont">
						<img src="img/propsellimg.png"/>
					</div>
					<div class="cont">
						<p class="title">
							Properties for sell
						</p>
						<span class="stats">
							Available: 245
						</span>
						<a href="0000000000000000000000" class="link">See properties &nbsp;<i class="fa fa-angle-right"></i></a>
					</div>
				</div>
			</div> 

			<div class="col-lg-3">
				<div class="btmerFlex flex">
					<div class="cont">
						<img src="img/hosuesrent.png"/>
					</div>
					<div class="cont">
						<p class="title">
							Properties for rent
						</p>
						<span class="stats">
							Available: 1054
						</span>
						<a href="0000000000000000000000" class="link">See shops &nbsp;<i class="fa fa-angle-right"></i></a>
					</div>
				</div>
			</div> 

			<div class="col-lg-3">
				<div class="btmerFlex flex">
					<div class="cont">
						<img src="img/shpiumg1.png"/>
					</div>
					<div class="cont">
						<p class="title">
							Shops for sell
						</p>
						<span class="stats">
							Available: 245
						</span>
						<a href="0000000000000000000000" class="link">See shops &nbsp;<i class="fa fa-angle-right"></i></a>
					</div>
				</div>
			</div> 

			<div class="col-lg-3">
				<div class="btmerFlex flex">
					<div class="cont">
						<img src="img/officeimg12.png"/>
					</div>
					<div class="cont">
						<p class="title">
							Offices for sell
						</p>
						<span class="stats">
							Available: 245
						</span>
						<a href="0000000000000000000000" class="link">See offices</a>
					</div>
				</div>
			</div> 

		</div>
	</div>
</div>



<div class="homSec2 padder1">
	<div class="container">
		<div class="row">
			
			<div class="col-lg-12">
				<div class="flex ai jcb widget-head-flex">
					<h2 class="widget-heading">Latest Property Listing</h2>
					<a href="0000000000000000000000" class="widget-btn">View recent properties</a>
				</div>
			</div> 



			<div class="col-lg-12">
					

				<div class="latSlid_container rel" ref={slickContainerRef}>

				<button onClick={handlePrevClick} class="prev">Previous</button>
				<button onClick={handleNextClick} class="next">Next</button>
{/* 					
                <button class="latBtn-next" onClick={handleNextClick}>
					<i class="fa fa-angle-right latBtn-next latpropHomBtn"></i>
                </button>
                <button class="latBtn-prev" onClick={handlePrevClick}>
					<i class="fa fa-angle-left latBtn-prev latpropHomBtn"></i>
                </button> */}


					<Slider  class="slitHomOuter" {...settings}>
						
						<div class="listhomSlide" >
							
							<a class="propListBox1" href="0000000000000000000000">

								<div class="rel propimgback">
									<span class="tag rent">Buying</span>
									<img src="img/prop1.png"/>
								</div>

								<h2 class="title" title="500 SQ Yards Bungalow for Sale in DHA Phase 6 Karachi">
									500 SQ Yards Bungalow for Sale in DHA Phase 6 Karachi
								</h2>

								<p class="buuilding">
									12 story flat
								</p>


								<div class="featsFlex flex">
									<div class="cont" title="Beds">
										<img src="img/bedsimg.png"/>
										<span>
											1
										</span>
									</div>
									<div class="cont" title="Bathrooms">
										<img src="img/bathicon.png"/>
										<span>
											2
										</span>
									</div>
									<div class="cont" title="Garage">
										<img src="img/garadeimg.png"/>
										<span>
											No
										</span>
									</div>
									<div class="cont" title="Area">
										<img src="img/sqhouse.png"/>
										<span>
											120 sq/feet
										</span>
									</div>
			 
								</div>


								<p class="desc">
									Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
									tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
									quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
									consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
									cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
									proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
								</p>


								<div class="proplistamt1 flex ai jcb">
									<p class="amt">
										900,000 Rs
									</p>
									<span class="detBtn">
										View Details &nbsp;<i class="fa fa-angle-right fs11"></i>
									</span>
								</div>

							</a>

						</div> 


						<div class="listhomSlide">
										
							<a class="propListBox1" href="0000000000000000000000">

								<div class="rel propimgback">
									<span class="tag rent2">Renting</span>
									<img src="img/prop2.png"/>
								</div>

								<h2 class="title" title="600 SQ Yard Bungalow for Sale in DHA Phase 6 Karachi">
									600 SQ Yard Bungalow for Sale in DHA Phase 6 Karachi
								</h2>

								<p class="buuilding">
									12 story flat
								</p>


								<div class="featsFlex flex">
									<div class="cont" title="Beds">
										<img src="img/bedsimg.png"/>
										<span>
											1
										</span>
									</div>
									<div class="cont" title="Bathrooms">
										<img src="img/bathicon.png"/>
										<span>
											2
										</span>
									</div>
									<div class="cont" title="Garage">
										<img src="img/garadeimg.png"/>
										<span>
											No
										</span>
									</div>
									<div class="cont" title="Area">
										<img src="img/sqhouse.png"/>
										<span>
											120 sq/feet
										</span>
									</div>
			 
								</div>


								<p class="desc">
									Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
									tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
									quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
									consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
									cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
									proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
								</p>


								<div class="proplistamt1 flex ai jcb">
									<p class="amt">
										900,000 Rs
									</p>
									<span class="detBtn">
										View Details &nbsp;<i class="fa fa-angle-right fs11"></i>
									</span>
								</div>

							</a>

						</div> 


						<div class="listhomSlide">
							
							<a class="propListBox1" href="0000000000000000000000">

								<div class="rel propimgback">
									<span class="tag rent">Buying</span>
									<img src="img/prop3.png"/>
								</div>

								<h2 class="title" title="500 SQ Yard Bungalow Available in DHA Phase 6 Karachi">
									500 SQ Yard Bungalow Available in DHA Phase 6 Karachi
								</h2>

								<p class="buuilding">
									12 story flat
								</p>


								<div class="featsFlex flex">
									<div class="cont" title="Beds">
										<img src="img/bedsimg.png"/>
										<span>
											1
										</span>
									</div>
									<div class="cont" title="Bathrooms">
										<img src="img/bathicon.png"/>
										<span>
											2
										</span>
									</div>
									<div class="cont" title="Garage">
										<img src="img/garadeimg.png"/>
										<span>
											No
										</span>
									</div>
									<div class="cont" title="Area">
										<img src="img/sqhouse.png"/>
										<span>
											120 sq/feet
										</span>
									</div>
			 
								</div>


								<p class="desc">
									Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
									tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
									quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
									consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
									cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
									proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
								</p>


								<div class="proplistamt1 flex ai jcb">
									<p class="amt">
										900,000 Rs
									</p>
									<span class="detBtn">
										View Details &nbsp;<i class="fa fa-angle-right fs11"></i>
									</span>
								</div>

							</a>

						</div>


						<div class="listhomSlide">
							
							<a class="propListBox1" href="0000000000000000000000">

								<div class="rel propimgback">
									<span class="tag rent">Buying</span>
									<img src="img/prop4.png"/>
								</div>

								<h2 class="title" title="Luxury property in Karachi">
									Luxury property in Karachi
								</h2>

								<p class="buuilding">
									12 story flat
								</p>


								<div class="featsFlex flex">
									<div class="cont" title="Beds">
										<img src="img/bedsimg.png"/>
										<span>
											1
										</span>
									</div>
									<div class="cont" title="Bathrooms">
										<img src="img/bathicon.png"/>
										<span>
											2
										</span>
									</div>
									<div class="cont" title="Garage">
										<img src="img/garadeimg.png"/>
										<span>
											No
										</span>
									</div>
									<div class="cont" title="Area">
										<img src="img/sqhouse.png"/>
										<span>
											120 sq/feet
										</span>
									</div>
			 
								</div>


								<p class="desc">
									Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
									tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
									quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
									consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
									cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
									proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
								</p>


								<div class="proplistamt1 flex ai jcb">
									<p class="amt">
										900,000 Rs
									</p>
									<span class="detBtn">
										View Details &nbsp;<i class="fa fa-angle-right fs11"></i>
									</span>
								</div>

							</a>

						</div>


						<div class="listhomSlide">
							
							<a class="propListBox1" href="0000000000000000000000">

								<div class="rel propimgback">
									<span class="tag rent">Buying</span>
									<img src="img/prop1.png"/>
								</div>

								<h2 class="title" title="500 SQ Yards Bungalow for Sale in DHA Phase 6 Karachi">
									500 SQ Yards Bungalow for Sale in DHA Phase 6 Karachi
								</h2>

								<p class="buuilding">
									12 story flat
								</p>


								<div class="featsFlex flex">
									<div class="cont" title="Beds">
										<img src="img/bedsimg.png"/>
										<span>
											1
										</span>
									</div>
									<div class="cont" title="Bathrooms">
										<img src="img/bathicon.png"/>
										<span>
											2
										</span>
									</div>
									<div class="cont" title="Garage">
										<img src="img/garadeimg.png"/>
										<span>
											No
										</span>
									</div>
									<div class="cont" title="Area">
										<img src="img/sqhouse.png"/>
										<span>
											120 sq/feet
										</span>
									</div>
			 
								</div>


								<p class="desc">
									Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
									tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
									quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
									consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
									cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
									proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
								</p>


								<div class="proplistamt1 flex ai jcb">
									<p class="amt">
										900,000 Rs
									</p>
									<span class="detBtn">
										View Details &nbsp;<i class="fa fa-angle-right fs11"></i>
									</span>
								</div>

							</a>

						</div> 


						<div class="listhomSlide">
							
							<div class="latPropLastSlide" align="center">
								<p>
									View more properties
								</p>

								<a href="0000000000000000000000">See properties</a>
							</div>

						</div> 


					</Slider>

				</div>	




			</div> 







		</div>
	</div>
</div> 




<div class="homSec4 padder1 bg1">

	<div class="container">
		<div class="row">
			
			<div class="col-lg-12">
				<div class="flex ai jcb">
					<div class="cont">
						<h2 class="widget-heading">Popular Cities</h2>
						<p class="fs12 cgray">
							Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
							tempor
						</p>
					</div>
					<a href="0000000000000000000000" class="widget-btn">Find in your city</a>
				</div>
			</div> 


			<div class="col-lg-3 col-6">
				<a class="cityBox1" href="0000000000000000000000">
					<img src="img/mazarequaid1.png"/>
					<div class="inner">
						<p class="txt">
							Karachi
						</p>
						<span class="linker">
							Karachi properties
						</span>
					</div>
				</a>
			</div> 

			<div class="col-lg-3 col-6">
				<a class="cityBox1" href="0000000000000000000000">
					<img src="img/lahoreimg1.png"/>
					<div class="inner">
						<p class="txt">
							Lahore
						</p>
						<span class="linker">
							Lahore properties
						</span>
					</div>
				</a>
			</div> 

			<div class="col-lg-3 col-6">
				<a class="cityBox1" href="0000000000000000000000">
					<img src="img/islamabadimg11.png"/>
					<div class="inner">
						<p class="txt">
							Islamabad
						</p>
						<span class="linker">
							Islamabad properties
						</span>
					</div>
				</a>
			</div> 

			<div class="col-lg-3 col-6">
				<a class="cityBox1" href="0000000000000000000000">
					<img src="img/faislabadimg11.png"/>
					<div class="inner">
						<p class="txt">
							Faisalabad
						</p>
						<span class="linker">
							Faisalabad properties
						</span>
					</div>
				</a>
			</div> 

			<div class="col-lg-3 col-6">
				<a class="cityBox1" href="0000000000000000000000">
					<img src="img/multanimg11.png"/>
					<div class="inner">
						<p class="txt">
							Multan
						</p>
						<span class="linker">
							Multan properties
						</span>
					</div>
				</a>
			</div> 

			<div class="col-lg-3 col-6">
				<a class="cityBox1" href="0000000000000000000000">
					<img src="img/quettaimg11.png"/>
					<div class="inner">
						<p class="txt">
							Quetta
						</p>
						<span class="linker">
							Quetta properties
						</span>
					</div>
				</a>
			</div> 

			<div class="col-lg-3 col-6">
				<a class="cityBox1" href="0000000000000000000000">
					<img src="img/peshawarimg11.png"/>
					<div class="inner">
						<p class="txt">
							Peshawar
						</p>
						<span class="linker">
							Peshawar properties
						</span>
					</div>
				</a>
			</div> 

			<div class="col-lg-3 col-6">
				<a class="cityBox1" href="0000000000000000000000">
					<img src="img/gujrawalaimg11.png"/>
					<div class="inner">
						<p class="txt">
							Gujrawalah
						</p>
						<span class="linker">
							Gujrawalah properties
						</span>
					</div>
				</a>
			</div> 

	</div>
</div>

</div> 






<div class="homSec5 padder1">
	<div class="container">
		
		<div class="row">
			
				<div class="col-lg-12">
					<div class="flex ai jcb widget-head-flex">
						<h2 class="widget-heading">Real Estate Agents</h2>
						<a href="0000000000000000000000" class="widget-btn">View all agents</a>
					</div>
				</div> 


				<div class="col-lg-3 col-6">
					<a class="homAgentBox" href="0000000000000000000000">
						<img src="img/agent1img.png"/>
						<p>
							AZPNRE Builder & Developer
						</p>
					</a>
				</div>

				<div class="col-lg-3 col-6">
					<a class="homAgentBox" href="0000000000000000000000">
						<img src="img/agent3.png"/>
						<p>
							ALI SAQLAIN Real Estate & Builders
						</p>
					</a>
				</div>

				<div class="col-lg-3 col-6">
					<a class="homAgentBox" href="0000000000000000000000">
						<img src="img/agent4.png"/>
						<p>
							Property Ideas
						</p>
					</a>
				</div>

				<div class="col-lg-3 col-6">
					<a class="homAgentBox" href="0000000000000000000000">
						<img src="img/agent5.png"/>
						<p>
							Ok Estate & Builders
						</p>
					</a>
				</div>

				<div class="col-lg-3 col-6">
					<a class="homAgentBox" href="0000000000000000000000">
						<img src="img/agent6.png"/>
						<p>
							ZA Associates
						</p>
					</a>
				</div>

				<div class="col-lg-3 col-6">
					<a class="homAgentBox" href="0000000000000000000000">
						<img src="img/agent7.png"/>
						<p>
							Universal Estate & Developer
						</p>
					</a>
				</div>

				<div class="col-lg-3 col-6">
					<a class="homAgentBox" href="0000000000000000000000">
						<img src="img/agent8.png"/>
						<p>
							Jaidad Group Of Companies
						</p>
					</a>
				</div>

				<div class="col-lg-3 col-6">
					<a class="homAgentBox" href="0000000000000000000000">
						<img src="img/agent10.png"/>
						<p>
							HR Properties
						</p>
					</a>
				</div>




		</div> 

	</div>
</div> 





<div class="homeBlogSec padder1">
	<div class="container">
		
		<div class="row">
			

				<div class="col-lg-12">
					<div class="flex ai jcb widget-head-flex">
						<h2 class="widget-heading">Real Estate Agents</h2>
						<a href="0000000000000000000000" class="widget-btn">View all blogs</a>
					</div>
				</div> 


				<div class="col-lg-3 col-6">
					<a class="blogHomBox" href="0000000000000000000000">
						<div class="thumbBack">
							<img src="img/blogsample1.png"/>
						</div>
						<h2 class="title" title="Zulekha Residency: Prime Location Meets Affordable Rates in Karachi">
							Zulekha Residency: Prime Location Meets Affordable Rates in Karachi
						</h2>
						<p class="date">
							<i class="fa fa-clock-o fs11"></i>&nbsp; 27th-January-2024
						</p>
						<p class="desc">
							Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
							tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
							quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
							consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
							cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
							proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
						</p>
					</a>
				</div> 

				<div class="col-lg-3 col-6">
					<a class="blogHomBox" href="0000000000000000000000">
						<div class="thumbBack">
							<img src="img/blogsample2.png"/>
						</div>
						<h2 class="title" title="Developed by Zulekha Builders, a reputable construction">
							Developed by Zulekha Builders, a reputable construction
						</h2>
						<p class="date">
							<i class="fa fa-clock-o fs11"></i>&nbsp; 27th-January-2024
						</p>
						<p class="desc">
							Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
							tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
							quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
							consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
							cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
							proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
						</p>
					</a>
				</div> 

				<div class="col-lg-3 col-6">
					<a class="blogHomBox" href="0000000000000000000000">
						<div class="thumbBack">
							<img src="img/blogsample3.png"/>
						</div>
						<h2 class="title" title="How to Book a Unit in Zulekha Residency?">
							How to Book a Unit in Zulekha Residency?
						</h2>
						<p class="date">
							<i class="fa fa-clock-o fs11"></i>&nbsp; 27th-January-2024
						</p>
						<p class="desc">
							Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
							tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
							quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
							consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
							cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
							proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
						</p>
					</a>
				</div>

				<div class="col-lg-3 col-6">
					<a class="blogHomBox" href="0000000000000000000000">
						<div class="thumbBack">
							<img src="img/blogsample4.png"/>
						</div>
						<h2 class="title" title="The Best Types of Beds for Pakistani Homes">
							The Best Types of Beds for Pakistani Homes
						</h2>
						<p class="date">
							<i class="fa fa-clock-o fs11"></i>&nbsp; 27th-January-2024
						</p>
						<p class="desc">
							Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
							tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
							quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
							consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
							cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
							proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
						</p>
					</a>
				</div> 

		</div>

	</div>
</div>
        </div>
     );
}
 
export default Home;