const Policy = () => {
    return (  
        <div className="policyname">
            <div class="privacyTermSection">
    <div class="container">
        <div class="content">
            <h1>Privacy Policy</h1>

<p><strong>Effective Date:</strong> [Insert Date]</p>

<h2>1. Introduction</h2>
<p>Welcome to [Your Company Name]. We value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website <a href="[insert website URL]">[insert website URL]</a>, use our services, or engage with us in the process of renting, buying, or selling plots and buildings.</p>

<h2>2. Information We Collect</h2>
<p>We may collect personal information from you in various ways, including:</p>
<ul>
    <li><strong>Personal Identification Information:</strong> Name, email address, phone number, postal address, and other identifiers.</li>
    <li><strong>Property Information:</strong> Details related to the property you are interested in, such as location, type, and price range.</li>
    <li><strong>Financial Information:</strong> Bank details, credit card information, or other payment-related details.</li>
    <li><strong>Communication Data:</strong> Information related to your communication with us, including emails, phone calls, and other interactions.</li>
</ul>

<h2>3. How We Use Your Information</h2>
<p>We may use the information we collect from you in the following ways:</p>
<ul>
    <li>To provide and manage the services related to renting, buying, and selling plots and buildings.</li>
    <li>To personalize your experience on our website and offer tailored services.</li>
    <li>To process transactions, including payments and related activities.</li>
    <li>To communicate with you regarding updates, offers, and other relevant information.</li>
    <li>To comply with legal obligations and resolve any disputes.</li>
</ul>

<h2>4. Sharing Your Information</h2>
<p>We do not sell, trade, or rent your personal information to others. However, we may share your information with third parties in the following circumstances:</p>
<ul>
    <li><strong>Service Providers:</strong> We may share your information with third-party service providers who assist us in our operations, such as payment processing, property valuation, and legal services.</li>
    <li><strong>Legal Compliance:</strong> We may disclose your information to comply with legal obligations, court orders, or other legal processes.</li>
    <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of our company, your information may be transferred to the new owner.</li>
</ul>

<h2>5. Security of Your Information</h2>
<p>We implement a variety of security measures to protect your personal information. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>

<h2>6. Your Rights</h2>
<p>You have the following rights regarding your personal information:</p>
<ul>
    <li><strong>Access:</strong> You may request access to the personal information we hold about you.</li>
    <li><strong>Correction:</strong> You may request correction of any inaccurate or incomplete information.</li>
    <li><strong>Deletion:</strong> You may request the deletion of your personal information, subject to legal obligations.</li>
    <li><strong>Objection:</strong> You may object to the processing of your personal information for certain purposes.</li>
</ul>

<h2>7. Cookies and Tracking Technologies</h2>
<p>Our website may use cookies and similar tracking technologies to enhance your experience. You can control the use of cookies through your browser settings.</p>

<h2>8. Third-Party Links</h2>
<p>Our website may contain links to third-party websites. We are not responsible for the privacy practices of these websites, and we encourage you to review their privacy policies.</p>

<h2>9. Changes to This Privacy Policy</h2>
<p>We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the effective date will be updated accordingly.</p>

<h2>10. Contact Us</h2>
<p>If you have any questions or concerns about this Privacy Policy or our practices, please contact us at:</p>
<p>[Your Company Name]<br/>
[Your Company Address]<br/>
<a href="mailto:[Email Address]">[Email Address]</a><br/>
[Phone Number]</p>
        </div>
    </div>
</div>
        </div>
    );
}
 
export default Policy;