const Dashboard_table = () => {
    return ( 
        <div className="dashboardClass">
            <div class="dashCont bg1 padder1">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-3">
                            <div class="dashNavBack">
                                <h2 class="heading">General</h2>
                                <ul class="leftNavUl">
                                    <li>
                                        <a href="0000000000000000000000">
                                            <span>
                                                <img src="img/dashboardimg1.png" class="navImg" />
                                                Summery
                                            </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="0000000000000000000000">
                                            <span>
                                                <img src="img/messageimg.png" class="navImg" />
                                                Messages
                                            </span>
                                            <span class="navTag bg-color1">15</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="0000000000000000000000">
                                            <span>
                                                <img src="img/booking456.png" class="navImg" />
                                                Bookings
                                            </span>
                                            <span class="navTag bg-color2">2</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="0000000000000000000000">
                                            <span>
                                                <img src="img/settingimg1.png" class="navImg" />
                                                Settings
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                                <h2 class="heading">Properties</h2>
                                <ul class="leftNavUl">
                                    <li>
                                        <a href="0000000000000000000000">
                                            <span>
                                                <img src="img/addpropimg.png" class="navImg" />
                                                My properties
                                            </span>
                                            <span class="navTag bg-color4">42</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="0000000000000000000000">
                                            <span>
                                                <img src="img/myporpimg.png" class="navImg" />
                                                Add property
                                            </span>
                                            <span class="dashNavInfoBack">
                                                <i class="fa fa-info"></i>
                                                <span class="dashTooltip">Add new properties using this option</span>
                                            </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="0000000000000000000000">
                                            <span>
                                                <img src="img/analyticsimg.png" class="navImg" />
                                                Analytics
                                            </span>
                                            <span class="navTag bg-color3">New</span>
                                        </a>
                                    </li>
                                </ul>
                                <h2 class="heading">Profile</h2>
                                <ul class="leftNavUl">
                                    <li>
                                        <a href="0000000000000000000000">
                                            <span>
                                                <img src="img/editprog12.png" class="navImg" />
                                                Edit profile
                                            </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="0000000000000000000000">
                                            <span>
                                                <img src="img/helpimg23.png" class="navImg" />
                                                Support
                                            </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="0000000000000000000000">
                                            <span>
                                                <img src="img/logsimg.png" class="navImg" />
                                                Logs
                                            </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="0000000000000000000000">
                                            <span>
                                                <img src="img/logoutimg.png" class="navImg" />
                                                Sign out
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                                <div class="dashNavSpec">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod empor incididunt ut labore et</p>
                                    <br />
                                    <a href="0000000000000000000000" class="underline cgray">Report a bug</a>
                                </div>
                            </div> 
                        </div> 
                        <div class="col-lg-9">
                            <div class="dashTitleBar">
                                <div class="cont">
                                    <h2 class="dashPageTitle">Dashboard - List of Properties</h2>
                                    <p class="desc">Your list summary will be visible here</p>
                                </div>
                            </div> 
                            <div class="dash-block">
                                <div class="dash-block-heading">
                                    <h2 class="head">Table Style</h2>
                                </div> 
                                <div class="dash-table-cont">
                                    <table class="dash-table">
                                        <tr>
                                            <th>Property</th>
                                            <th>Amount</th>
                                            <th>Date</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="dashUserBox1">
                                                    <div class="cont">
                                                        <img src="img/prop2.png" class="img img-square" />
                                                    </div>
                                                    <div class="cont">
                                                        <p class="name">Bahria Town Phase 3</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <p>200,2000,2000 PKR</p>
                                            </td>
                                            <td>
                                                <p>20-Jan-2024</p>
                                            </td>
                                            <td>
                                                <span class="tag tag-green">Rent</span>
                                            </td>
                                            <td>
                                                <a href="0000000000000000000000" class="table-btns btn-green"><i class="fa fa-eye"></i> View</a>
                                                <a href="0000000000000000000000" class="table-btns btn-blue"><i class="fa fa-edit"></i> Edit</a>
                                                <a href="0000000000000000000000" class="table-icon-btns btn-red"><i class="fa fa-times"></i></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="dashUserBox1">
                                                    <div class="cont">
                                                        <img src="img/prop2.png" class="img img-square" />
                                                    </div>
                                                    <div class="cont">
                                                        <p class="name">Bahria Town Phase 3</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <p>53,00,000 PKR</p>
                                            </td>
                                            <td>
                                                <p>20-Jan-2024</p>
                                            </td>
                                            <td>
                                                <span class="tag tag-red">Sold</span>
                                            </td>
                                            <td>
                                                <a href="0000000000000000000000" class="table-btns btn-green"><i class="fa fa-eye"></i> View</a>
                                                <a href="0000000000000000000000" class="table-btns btn-blue"><i class="fa fa-edit"></i> Edit</a>
                                                <a href="0000000000000000000000" class="table-icon-btns btn-red"><i class="fa fa-times"></i></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="dashUserBox1">
                                                    <div class="cont">
                                                        <img src="img/prop2.png" class="img img-square" />
                                                    </div>
                                                    <div class="cont">
                                                        <p class="name">Bahria Town Phase 3</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <p>400,000 PKR</p>
                                            </td>
                                            <td>
                                                <p>20-Jan-2024</p>
                                            </td>
                                            <td>
                                                <span class="tag tag-blue">On Sale</span>
                                            </td>
                                            <td>
                                                <a href="0000000000000000000000" class="table-btns btn-green"><i class="fa fa-eye"></i> View</a>
                                                <a href="0000000000000000000000" class="table-btns btn-blue"><i class="fa fa-edit"></i> Edit</a>
                                                <a href="0000000000000000000000" class="table-icon-btns btn-red"><i class="fa fa-times"></i></a>
                                            </td>
                                        </tr>
                                    </table>
                                </div> 
                            </div>  
                        </div> 
                    </div> 
                </div>
            </div>
        </div>
    );
}
 
export default Dashboard_table;