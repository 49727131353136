import React, { useRef } from 'react';

const Blogs = () => {
    const instagramLinkRef = useRef(null);

    const copyLink = () => {
        const copyText = instagramLinkRef.current;
        copyText.select();
        document.execCommand("copy");
        alert("Link copied to clipboard: " + copyText.value);
    };

    return (
        <div className="blogs">
            <div class="blogView padder1">
    <div class="container">
        <div class="row jc">
            
            <div class="col-lg-7">
                
                <div class="breadcrumbs">
                    <a href="/">Home</a>
                    <span><i class="fa fa-angle-right"></i></span>
                    <a href="/blogs_list">Blogs</a>
                    <span><i class="fa fa-angle-right"></i></span>
                    <a href="0000000000000" class="current">Property</a>
                </div>

                <div class="block1">
                    
                    <div class="thumbBack">
                        <span class="tag">Property</span>
                        <img src="img/prop5.png" class="img" />
                    </div>

                    <div class="inner">
                        <h2 class="title">
                            Property rates hikes in 2024 in Karachi!
                        </h2>
                        <span class="tag"><i class="fa fa-clock-o"></i>&nbsp; Posted: 12-Dec-2024</span>

                        <hr />

                        <div class="descCont">
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                                cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                                proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </p>                        
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                                cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                                proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </p>                        
                        </div>

                        <hr />

                        <div class="blogVireTags">
                            <a href="0000000000000000000000">Properties</a>
                            <a href="0000000000000000000000">Karachi properties</a>
                            <a href="0000000000000000000000">HR assosiates</a>
                            <a href="0000000000000000000000">Cheap houses</a>
                            <a href="0000000000000000000000">Fraud alert</a>
                            <a href="0000000000000000000000">Commission based selling</a>
                            <a href="0000000000000000000000">Best properties</a>
                        </div>

                    </div>

                </div>

                <div class="block1 mt20">
                    <div class="inner">
                        <div class="blogSocial flex ai jcb">

                            <h2 class="heading1">Share Blog</h2>

                            <div class="flex ai blogSocIcons">

                                <a href="https://www.facebook.com/sharer/sharer.php?u=YOUR_BLOG_URL" target="_blank"><i class="fa fa-facebook"></i></a>

                                <a href="https://twitter.com/intent/tweet?url=YOUR_BLOG_URL&text=YOUR_BLOG_TITLE" target="_blank"><i class="fa fa-twitter"></i></a>

                                <a href="https://www.linkedin.com/shareArticle?mini=true&url=YOUR_BLOG_URL&title=YOUR_BLOG_TITLE&summary=YOUR_BLOG_SUMMARY&source=YOUR_WEBSITE" target="_blank"><i class="fa fa-linkedin"></i></a>

                                <a href="https://api.whatsapp.com/send?text=YOUR_BLOG_TITLE%20YOUR_BLOG_URL" target="_blank"><i class="fa fa-whatsapp"></i></a>

                                <span onclick="copyLink()"><i class="fa fa-copy"></i></span>

                            </div>

                        </div>
                    </div>
                </div>

            </div>

            <input type="text" value="YOUR_BLOG_URL" ref={instagramLinkRef} style={{ display: 'none' }} />

            <div class="col-lg-3">

                <br />
                <div class="blogViewSearch">

                    <form method="POST" action="00000000000000">
                        <h2 class="sidebarHead">Search Blogs</h2>
                        <div class="flex">
                            <input type="text" name="search" class="input1 m0" placeholder="Search for blogs here..." />
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <button class="btn1 btnpad1"><i class="fa fa-search"></i></button>
                        </div>
                    </form>

                </div>

                <hr class="hr1 m20" />

                <h2 class="sidebarHead">Recent Blogs</h2>

                <a href="0000000000000" class="recBlogBack">

                    <div class="thumbBack">
                        <img src="img/prop3.png" />
                    </div>

                    <div class="inner2">
                        
                        <h2 class="sideTitle">
                            Property problems that will keep on occuring soon!
                        </h2>

                        <p class="sideDesc">
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                            tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                            quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                            consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                            cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                            proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                        </p>

                        <a href="0000000000000000000000" class="bloglistbtn1">Read blog</a>

                    </div>

                </a>

                <a href="0000000000000" class="recBlogBack">

                    <div class="thumbBack">
                        <img src="img/prop3.png" />
                    </div>

                    <div class="inner2">
                        
                        <h2 class="sideTitle">
                            Property problems that will keep on occuring soon!
                        </h2>

                        <p class="sideDesc">
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                            tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                            quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                            consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                            cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                            proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                        </p>

                        <a href="0000000000000000000000" class="bloglistbtn1">Read blog</a>

                    </div>

                </a>

                <a href="0000000000000" class="recBlogBack">

                    <div class="thumbBack">
                        <img src="img/prop3.png" />
                    </div>

                    <div class="inner2">
                        
                        <h2 class="sideTitle">
                            Property problems that will keep on occuring soon!
                        </h2>

                        <p class="sideDesc">
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                            tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                            quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                            consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                            cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                            proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                        </p>

                        <a href="0000000000000000000000" class="bloglistbtn1">Read blog</a>

                    </div>

                </a>

            </div>

        </div>
    </div>
</div>
        </div>
     );
}
 
export default Blogs;