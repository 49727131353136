const Login = () => {
    return ( 
    <div className="loginClass">
        <section className="login-section registerPage logPage">

<div className="login-div">

    <div className="container">
        

        <div className="row jc">
            
            <div className="col-lg-3 regCol_1">
                
                <div className="regLeft1 rel">
                    
                    <div className="abs inner1">
                        <h2>
                            Not a member?
                        </h2>
                        <a href="/register" className="specBtn">Create an account</a>
                    </div>

                </div>

            </div>


            <div className="col-lg-4 regCol_2">
                

                        <div className="formsBack">
                            
                            <div className="row ai">
                                <div className="col-lg-6 regTxt_col">
                                    <h2 className="logRegTitle">
                                        Log In
                                    </h2>
                                </div>
                                
                                <div className="col-lg-6 regLogo_col" align="right">
                                    <a href="/"><img src="img/logo-main.png" className="logoLogReg" /></a>
                                </div>

                            </div>

                            <br /><br />

                            <div className="row">

                                <div className="col-lg-12">
                                    <label for="registerAs">Registering as:</label>
                                    <select id="registerAs" name="registerAs" className="input1">
                                        <option value="Company">Company</option>
                                        <option value="Agent">Agent</option>
                                        <option value="Customer">Customer</option>
                                    </select>
                                </div>

                                <div className="col-lg-12">
                                    <span>Email address</span>
                                    <input type="email" name="email" id="" className="input1" />
                                </div>

                                <div className="col-lg-12">
                                    <span>Password</span>
                                    <input type="password" name="password" id="" className="input1" />
                                </div>


                                <div className="col-lg-6">
                                    <label className="rememberme m0">
                                        <input type="checkbox" />
                                        &nbsp;
                                        <span>Remember me</span>
                                    </label>
                                </div>

                                <div className="col-lg-6" align="right">
                                    <a href="0000000000000000000000" className="cgray fs13">Forgot password?</a>
                                </div>

                                <div className="col-lg-12">
                                    <input type="submit" value="Log In" className="btn3" />
                                </div>
                                <div className="loginwith">
                                    <span>Or Log in with</span>
                                </div>  
                                <div className="col-lg-12">
                                    <a className="btn-google" href="">
                                        <div className="google-content">
                                            <div className="logo">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                                                <defs><path id="a" d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"/></defs><clipPath id="b"><use href="#a" overflow="visible"/></clipPath><path clip-path="url(#b)" fill="#FBBC05" d="M0 37V11l17 13z"/><path clip-path="url(#b)" fill="#EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z"/><path clip-path="url(#b)" fill="#34A853" d="M0 37l30-23 7.9 1L48 0v48H0z"/><path clip-path="url(#b)" fill="#4285F4" d="M48 48L17 24l-4-3 35-10z"/>
                                            </svg>
                                            </div>
                                            <p>Sign in with Google</p>
                                        </div>
                                    </a>
                                </div>  
                                <div className="col-lg-12">
                                    <a className="btn-fb" href="">
                                        <div className="fb-content">
                                            <div className="logo">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" version="1">
                                    <path fill="#FFFFFF" d="M32 30a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h28a2 2 0 0 1 2 2v28z"/>
                                    <path fill="#4267b2" d="M22 32V20h4l1-5h-5v-2c0-2 1.002-3 3-3h2V5h-4c-3.675 0-6 2.881-6 7v3h-4v5h4v12h5z"/>
                                  </svg>
                                            </div>
                                            <p>Sign in with Facebook</p>
                                        </div>
                                    </a>
                                </div>  





                        </div>


                </div>

            </div>


        </div>


    </div>

</div>
</section>


    </div> 
    );
}
 
export default Login;