import { useRef, useEffect, useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Single = () => {

	const [formData, setFormData] = useState({
		visitDate: '',
		fname: '',
		lname: '',
		email: '',
		msg: '',
		agreeTerms: false,
	  });
	
	  // Handle input changes
	  const handleChange = (e) => {
		const { name, value, type, checked } = e.target;
		setFormData((prevData) => ({
		  ...prevData,
		  [name]: type === 'checkbox' ? checked : value,
		}));
	  };
	
	  // Handle form submission
	  const handleSubmit = (e) => {
		e.preventDefault();
		console.log('Form submitted:', formData);
		// Add further logic like API calls here
	  };


    const sliderTop1 = useRef(null);
    const singleNav = useRef(null);

    const [sliderTop1Settings, setSliderTop1Settings] = useState({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false, // Disable default arrows
        fade: true,
        asNavFor: null,
    });

    const [singleNavSettings, setSingleNavSettings] = useState({
        slidesToShow: 4,
        slidesToScroll: 1,
        asNavFor: null,
        focusOnSelect: true,
    });

    useEffect(() => {
        if (sliderTop1.current && singleNav.current) {
            setSliderTop1Settings((prev) => ({
                ...prev,
                asNavFor: singleNav.current,
            }));
            setSingleNavSettings((prev) => ({
                ...prev,
                asNavFor: sliderTop1.current,
            }));
        }
    }, [sliderTop1, singleNav]);

    // Handler for next and prev buttons
    const nextSlide = () => {
        sliderTop1.current.slickNext();
    };

    const prevSlide = () => {
        sliderTop1.current.slickPrev();
    };

	// Custom Next Arrow
const NextArrow = ({ onClick }) => {
    return (
        <div className="latBtn-next latpropHomBtn" onClick={onClick}>
            <i className="fa fa-angle-right"></i>
        </div>
    );
};

// Custom Prev Arrow
const PrevArrow = ({ onClick }) => {
    return (
        <div className="latBtn-prev latpropHomBtn" onClick={onClick}>
            <i className="fa fa-angle-left"></i>
        </div>
    );
};

const settings = {
	infinite: false,
	speed: 500,
	slidesToShow: 4,
	slidesToScroll: 1,
	dots: false,
	nextArrow: <NextArrow />,
	prevArrow: <PrevArrow />,
	responsive: [
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 3,
				infinite: true,
				dots: true,
			},
		},
		{
			breakpoint: 600,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
			},
		},
		{
			breakpoint: 480,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
			},
		},
	],
};

    return (
        <div className="singleClass">
            <div className="breadCrumbs">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner flex ai">
                                <a href="0000000000000000000000:">Home</a>
                                <span><i className="fa fa-angle-right"></i></span>
                                <a href="0000000000000000000000:">Properties</a>
                                <span><i className="fa fa-angle-right"></i></span>
                                <a href="0000000000000000000000:" className="current">Karachi</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="singleMain">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9">
                            <div className="singleLeft">
                                <div className="singleSlidOuter rel">
                                    <span className="tag tag-buy">Buying</span>
									<i className="fa fa-angle-right single-next singleBtnSlide" onClick={nextSlide}></i>
                                    <i className="fa fa-angle-left single-prev singleBtnSlide" onClick={prevSlide}></i>

                                    <div className="singleSlideCont">
                                        <Slider
                                            ref={sliderTop1}
                                            className="sliderTop1"
                                            {...sliderTop1Settings}
                                        >
                                            <div className="slideSingle">
                                                <img src="img/prop1.png" alt="Property 1" />
                                            </div>
                                            <div className="slideSingle">
                                                <img src="img/prop3.png" alt="Property 3" />
                                            </div>
                                            <div className="slideSingle">
                                                <img src="img/prop3.png" alt="Property 3" />
                                            </div>
                                            <div className="slideSingle">
                                                <img src="img/prop4.png" alt="Property 4" />
                                            </div>
                                            <div className="slideSingle">
                                                <img src="img/prop5.png" alt="Property 5" />
                                            </div>
                                        </Slider>

                                        <Slider
                                            ref={singleNav}
                                            className="singleNav"
                                            {...singleNavSettings}
                                        >
                                            <div className="slidNav-single">
                                                <img src="img/prop1.png" alt="Property 1" />
                                            </div>
                                            <div className="slidNav-single">
                                                <img src="img/prop3.png" alt="Property 3" />
                                            </div>
                                            <div className="slidNav-single">
                                                <img src="img/prop3.png" alt="Property 3" />
                                            </div>
                                            <div className="slidNav-single">
                                                <img src="img/prop4.png" alt="Property 4" />
                                            </div>
                                            <div className="slidNav-single">
                                                <img src="img/prop5.png" alt="Property 5" />
                                            </div>
                                        </Slider>
                                    </div>
                                </div>

                                <div className="inner">
                                    <div className="flex ai jcb">
                                        <h2 className="heading">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed 
                                        </h2>
                                        <div className="singleExtraIcon flex">
                                            <a href="0000000000000000000000" title="Report">
                                                <i className="fa fa-flag"></i>
                                            </a>
                                            <a href="0000000000000000000000" title="Share">
                                                <i className="fa fa-share-alt"></i>
                                            </a>
                                        </div>
                                    </div>

                                    <div className="flex ai loc1">
                                        <i className="fa fa-map-marker fs13"></i>
                                        <span>DHA Phase V Ext, Karachi</span>
                                    </div>

                                    <div className="row feat1">
                                        <div className="col-lg-3">
                                            <h2>Bedrooms</h2>
                                            <p>5 Bedrooms - 1 Kitchen</p>
                                        </div>
                                        <div className="col-lg-3">
                                            <h2>Restrooms</h2>
                                            <p>2 Restrooms</p>
                                        </div>
                                        <div className="col-lg-3">
                                            <h2>Garage</h2>
                                            <p>1 car garage</p>
                                        </div>
                                        <div className="col-lg-3">
                                            <h2>Property Area</h2>
                                            <p>200 sq / feet</p>
                                        </div>
                                    </div>

                                    <h2 className="singleHeading">Description</h2>
                                    <p className="desc">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. 
                                        <br /><br />
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus ve
                                    </p>

                                    <br /><br />

                                    <div className="row">
                                        <div className="col-lg-6">
                                            <h2 className="singleHeading m0">Location</h2>
                                        </div>
                                        <div className="col-lg-6" align="right">
                                            <a href="0000000000000000000000" className="csecond underline fs12">Find on map</a>
                                        </div>
                                    </div>

                                    <br />

                                    <iframe
                                        className="singleMap"
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3619.8472534609887!2d67.08312561032078!3d24.869065977831486!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33ea1ff4ecbd9%3A0x1c26f03a9afa09c2!2sCodup!5e0!3m2!1sen!2s!4v1724270217601!5m2!1sen!2s"
                                        width="100%"
                                        height="400"
                                        style={{ border: "0" }}
                                        allowFullScreen=""
                                        loading="lazy"
                                        referrerPolicy="no-referrer-when-downgrade"
                                    ></iframe>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3">
                            <div className="singleRight">
                                <div className="singleAmtBack">
                                    <div className="amt flex ai jcb">
                                        <p className="val">10,000,000</p>
                                        <p className="sign">PKR</p>
                                    </div>
                                    <div className="singlAgentBack flex ai">
                                        <div><img src="img/agent10.png" className="agentimg" alt="Agent" /></div>
                                        <div className="cont">
                                            <a href="0000000000000000000000" className="fs16 fw3">
                                                HR Assosiates
                                                <img src="img/shield1.png" width="13px" title="Trusted Agent" alt="Trusted Agent" />
                                            </a>
                                            <p className="cgray fs13">12 more properties</p>
                                        </div>
                                    </div>
                                    <div className="flex singMainBtn us">
                                        <a href="0000000000000000000000" className="btn1 btns callBtn flex ai jc">
                                            <i className="fa fa-phone"></i>&nbsp; CALL NOW
                                        </a>
                                        <a href="0000000000000000000000" className="btn2 btns emailBtn">EMAIL US</a>
                                    </div>
                                </div>
								<button type="button" className="btn1 btns block w-100" data-bs-toggle="modal" data-bs-target="#bookVisitModal">
        <i className="fa fa-calendar mr10"></i>
        Book a visit
      </button>

      {/* Modal */}
      <div className="modal fade" id="bookVisitModal" tabIndex="-1" aria-labelledby="bookVisitModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="bookVisitModalLabel">Book a Visit</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              {/* Form */}
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="visitDate" className="form-label">Select Date</label>
                  <input
                    type="date"
                    className="form-control"
                    id="visitDate"
                    name="visitDate"
                    value={formData.visitDate}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label htmlFor="fname" className="form-label">First name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="fname"
                      name="fname"
                      value={formData.fname}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="lname" className="form-label">Last name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="lname"
                      name="lname"
                      value={formData.lname}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">Email address</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="msg" className="form-label">Message</label>
                  <textarea
                    className="form-control"
                    id="msg"
                    name="msg"
                    rows="3"
                    value={formData.msg}
                    onChange={handleChange}
                  ></textarea>
                </div>
                <div className="mb-3 form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="agreeTerms"
                    name="agreeTerms"
                    checked={formData.agreeTerms}
                    onChange={handleChange}
                    required
                  />
                  <label className="form-check-label" htmlFor="agreeTerms">I agree to the terms and conditions</label>
                </div>
                <button type="submit" className="btn btn-primary w-100">Confirm Booking</button>
              </form>
            </div>
          </div>
        </div>
      </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

			<div className="homSec2 padder1 listPageRecomm bg1">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="flex ai jcb widget-head-flex">
                            <h2 className="widget-heading">Recommended for you</h2>
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="latSlid_container rel">
                            <Slider {...settings} className="slitHomOuter">
                                {/* Slide 1 */}
                                <div className="listhomSlide">
                                    <a className="propListBox1" href="#">
                                        <div className="rel propimgback">
                                            <span className="tag rent">Buying</span>
                                            <img src="img/prop1.png" alt="Property 1" />
                                        </div>
                                        <h2 className="title">500 SQ Yards Bungalow for Sale</h2>
                                        <p className="building">12 story flat</p>
                                        <div className="featsFlex flex">
                                            <div className="cont" title="Beds">
                                                <img src="img/bedsimg.png" alt="Beds" />
                                                <span>1</span>
                                            </div>
                                            <div className="cont" title="Bathrooms">
                                                <img src="img/bathicon.png" alt="Bathrooms" />
                                                <span>2</span>
                                            </div>
                                            <div className="cont" title="Garage">
                                                <img src="img/garadeimg.png" alt="Garage" />
                                                <span>No</span>
                                            </div>
                                            <div className="cont" title="Area">
                                                <img src="img/sqhouse.png" alt="Area" />
                                                <span>120 sq/feet</span>
                                            </div>
                                        </div>
                                        <p className="desc">
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                        </p>
                                        <div className="proplistamt1 flex ai jcb">
                                            <p className="amt">900,000 Rs</p>
                                            <span className="detBtn">View Details &nbsp;<i className="fa fa-angle-right fs11"></i></span>
                                        </div>
                                    </a>
                                </div>
								<div className="listhomSlide">
                                    <a className="propListBox1" href="#">
                                        <div className="rel propimgback">
                                            <span className="tag rent">Buying</span>
                                            <img src="img/prop1.png" alt="Property 1" />
                                        </div>
                                        <h2 className="title">500 SQ Yards Bungalow for Sale</h2>
                                        <p className="building">12 story flat</p>
                                        <div className="featsFlex flex">
                                            <div className="cont" title="Beds">
                                                <img src="img/bedsimg.png" alt="Beds" />
                                                <span>1</span>
                                            </div>
                                            <div className="cont" title="Bathrooms">
                                                <img src="img/bathicon.png" alt="Bathrooms" />
                                                <span>2</span>
                                            </div>
                                            <div className="cont" title="Garage">
                                                <img src="img/garadeimg.png" alt="Garage" />
                                                <span>No</span>
                                            </div>
                                            <div className="cont" title="Area">
                                                <img src="img/sqhouse.png" alt="Area" />
                                                <span>120 sq/feet</span>
                                            </div>
                                        </div>
                                        <p className="desc">
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                        </p>
                                        <div className="proplistamt1 flex ai jcb">
                                            <p className="amt">900,000 Rs</p>
                                            <span className="detBtn">View Details &nbsp;<i className="fa fa-angle-right fs11"></i></span>
                                        </div>
                                    </a>
                                </div>
								<div className="listhomSlide">
                                    <a className="propListBox1" href="#">
                                        <div className="rel propimgback">
                                            <span className="tag rent">Buying</span>
                                            <img src="img/prop1.png" alt="Property 1" />
                                        </div>
                                        <h2 className="title">500 SQ Yards Bungalow for Sale</h2>
                                        <p className="building">12 story flat</p>
                                        <div className="featsFlex flex">
                                            <div className="cont" title="Beds">
                                                <img src="img/bedsimg.png" alt="Beds" />
                                                <span>1</span>
                                            </div>
                                            <div className="cont" title="Bathrooms">
                                                <img src="img/bathicon.png" alt="Bathrooms" />
                                                <span>2</span>
                                            </div>
                                            <div className="cont" title="Garage">
                                                <img src="img/garadeimg.png" alt="Garage" />
                                                <span>No</span>
                                            </div>
                                            <div className="cont" title="Area">
                                                <img src="img/sqhouse.png" alt="Area" />
                                                <span>120 sq/feet</span>
                                            </div>
                                        </div>
                                        <p className="desc">
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                        </p>
                                        <div className="proplistamt1 flex ai jcb">
                                            <p className="amt">900,000 Rs</p>
                                            <span className="detBtn">View Details &nbsp;<i className="fa fa-angle-right fs11"></i></span>
                                        </div>
                                    </a>
                                </div>
								<div className="listhomSlide">
                                    <a className="propListBox1" href="#">
                                        <div className="rel propimgback">
                                            <span className="tag rent">Buying</span>
                                            <img src="img/prop1.png" alt="Property 1" />
                                        </div>
                                        <h2 className="title">500 SQ Yards Bungalow for Sale</h2>
                                        <p className="building">12 story flat</p>
                                        <div className="featsFlex flex">
                                            <div className="cont" title="Beds">
                                                <img src="img/bedsimg.png" alt="Beds" />
                                                <span>1</span>
                                            </div>
                                            <div className="cont" title="Bathrooms">
                                                <img src="img/bathicon.png" alt="Bathrooms" />
                                                <span>2</span>
                                            </div>
                                            <div className="cont" title="Garage">
                                                <img src="img/garadeimg.png" alt="Garage" />
                                                <span>No</span>
                                            </div>
                                            <div className="cont" title="Area">
                                                <img src="img/sqhouse.png" alt="Area" />
                                                <span>120 sq/feet</span>
                                            </div>
                                        </div>
                                        <p className="desc">
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                        </p>
                                        <div className="proplistamt1 flex ai jcb">
                                            <p className="amt">900,000 Rs</p>
                                            <span className="detBtn">View Details &nbsp;<i className="fa fa-angle-right fs11"></i></span>
                                        </div>
                                    </a>
                                </div>
								<div className="listhomSlide">
                                    <a className="propListBox1" href="#">
                                        <div className="rel propimgback">
                                            <span className="tag rent">Buying</span>
                                            <img src="img/prop1.png" alt="Property 1" />
                                        </div>
                                        <h2 className="title">500 SQ Yards Bungalow for Sale</h2>
                                        <p className="building">12 story flat</p>
                                        <div className="featsFlex flex">
                                            <div className="cont" title="Beds">
                                                <img src="img/bedsimg.png" alt="Beds" />
                                                <span>1</span>
                                            </div>
                                            <div className="cont" title="Bathrooms">
                                                <img src="img/bathicon.png" alt="Bathrooms" />
                                                <span>2</span>
                                            </div>
                                            <div className="cont" title="Garage">
                                                <img src="img/garadeimg.png" alt="Garage" />
                                                <span>No</span>
                                            </div>
                                            <div className="cont" title="Area">
                                                <img src="img/sqhouse.png" alt="Area" />
                                                <span>120 sq/feet</span>
                                            </div>
                                        </div>
                                        <p className="desc">
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                        </p>
                                        <div className="proplistamt1 flex ai jcb">
                                            <p className="amt">900,000 Rs</p>
                                            <span className="detBtn">View Details &nbsp;<i className="fa fa-angle-right fs11"></i></span>
                                        </div>
                                    </a>
                                </div>
								<div class="listhomSlide">

							<div class="latPropLastSlide" align="center">
								<p>
									View more properties
								</p>

								<a href="0000000000000000000000">See properties</a>
							</div>

						</div>
                                {/* Add more slides similarly */}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
}

export default Single;